import loadComponents from "gia/loadComponents";
import removeComponents from "gia/removeComponents";
import eventbus from "gia/eventbus";

/////////////////////
// Load components //
/////////////////////

import { siteHeader__button } from "./components/site-header/button/site-header__button";
import { siteHeader__selectButton } from "./components/site-header/select-button/siteHeader__select-button";
import { siteHeader__rangeSlider } from "./components/site-header/range-slider/site-header__range-slider";
import { siteHeader__total } from "./components/site-header/siteHeader__total";
import { siteHeader__logo } from "./components/site-header/site-header__logo";
//////
import { mobileSiteHeader } from "./components/mobile-site-header/mobile-site-header";
import { mobileSiteHeader__rangeSlider } from "./components/mobile-site-header/mobile-site-header__range-slider";
import { mobileSiteHeader__menuButton } from "./components/mobile-site-header/mobile-site-header__menu-button";
import { mobileSiteHeader__selectButton } from "./components/mobile-site-header/mobile-site-header__select-button";
//////
import { sidebar } from "./components/sidebar/sidebar";
import { sidefooter } from "./components/sidefooter/sidefooter";
import { sidepage } from "./components/sidepage/sidepage";
import { sidepage__slider } from "./components/sidepage/sidepage__slider";
//////
import { filterButton } from "./components/filter-button/filter-button";
//////
import { filterMenuButton } from "./components/filter-menu-button/filter-menu-button";
import { filterMenu } from "./components/filter-menu/filter-menu";
//////
import { activeFiltersDisplay } from "./components/active-filters-display/active-filters-display";
//////
import { mobileFilterMenu } from "./components/mobile-filter-menu/mobile-filter-menu";
import { mobileFilterMenu__button_toggle } from "./components/mobile-filter-menu/mobile-filter-menu__button_toggle";
import { mobileFilterMenu__entry } from "./components/mobile-filter-menu/mobile-filter-menu__entry";
import { mobileFilterMenu__entry_price } from "./components/mobile-filter-menu/mobile-filter-menu__entry_price";
//////
import { counter } from "./components/counter/counter";
import { cart } from "./components/cart/cart";
//////
import { pageCover } from "./components/page-cover/pageCover";
import { pageCover_orientationInfo_phone } from "./components/page-cover/page-cover_orientation-info_phone";
//////
import { grid_muuri } from "./components/grid/grid_muuri";
import { productViewer } from "./components/product-viewer/product-viewer";

loadComponents({
  siteHeader__button: siteHeader__button,
  siteHeader__selectButton: siteHeader__selectButton,
  siteHeader__rangeSlider: siteHeader__rangeSlider,
  siteHeader__total: siteHeader__total,
  siteHeader__logo: siteHeader__logo,
  //////
  mobileSiteHeader: mobileSiteHeader,
  mobileSiteHeader__rangeSlider: mobileSiteHeader__rangeSlider,
  mobileSiteHeader__menuButton: mobileSiteHeader__menuButton,
  mobileSiteHeader__selectButton: mobileSiteHeader__selectButton,
  //////
  sidebar: sidebar,
  sidefooter: sidefooter,
  sidepage: sidepage,
  sidepage__slider: sidepage__slider,
  //////
  filterButton: filterButton,
  //////
  filterMenuButton: filterMenuButton,
  filterMenu: filterMenu,
  //////
  activeFiltersDisplay: activeFiltersDisplay,
  //////
  mobileFilterMenu: mobileFilterMenu,
  mobileFilterMenu__button_toggle: mobileFilterMenu__button_toggle,
  mobileFilterMenu__entry: mobileFilterMenu__entry,
  mobileFilterMenu__entry_price: mobileFilterMenu__entry_price,
  //////
  counter: counter,
  cart: cart,
  //////
  pageCover: pageCover,
  pageCover_orientationInfo_phone: pageCover_orientationInfo_phone,
  //////
  grid_muuri: grid_muuri,
  productViewer: productViewer,
});

///////////////////////
// Set CSS-variables //
///////////////////////

if (window.innerWidth >= 1000) {
  const root = document.documentElement;
  root.style.setProperty("--height_filter-menu", document.querySelector(".site-header").offsetHeight + "px");
}

///////////////////////////
// Track scroll-distance //
///////////////////////////

if (window.innerWidth >= 1000) {
  document.addEventListener("scroll", () => {
    const scroll_distance = document.documentElement.scrollTop;
    const height_siteHeader = document.querySelector(".site-header").offsetHeight;
    //////
    if (scroll_distance > height_siteHeader * 0.9) {
      eventbus.emit("scroll_enter_page");
    } else {
      eventbus.emit("scroll_leave_page");
    }
  });
} else {
  window.addEventListener("scroll", () => {
    const scroll_distance = document.documentElement.scrollTop;
    const height_siteHeader = document.querySelector(".mobile-site-header").offsetHeight;
    //////
    if (scroll_distance > height_siteHeader * 0.9) {
      eventbus.emit("scroll_enter_page");
    } else {
      eventbus.emit("scroll_leave_page");
    }
  });
}

///////////////////////////
// Global eventbus calls //
///////////////////////////

window.addEventListener("resize", () => {
  eventbus.emit("window_resize", { window: { width: window.innerWidth, height: window.innerHeight } });
});

////////////////////////////////
// Special eventbus-responses //
////////////////////////////////

const body = document.body;

eventbus.on("sidepage_show", (args) => {
  const context = args.context;
  //////
  if (context === "imprint") {
    body.classList.remove("sidepage-context_privacy");
    body.classList.remove("sidepage-context_about");
    body.classList.add("sidepage-context_imprint");
  } else {
    if (context === "privacy") {
      body.classList.remove("sidepage-context_imprint");
      body.classList.remove("sidepage-context_about");
      body.classList.add("sidepage-context_privacy");
    } else {
      if (context === "about") {
        body.classList.remove("sidepage-context_imprint");
        body.classList.remove("sidepage-context_privacy");
        body.classList.add("sidepage-context_about");
      }
    }
  }
  //////
  body.classList.add("sidepage_open");
});
eventbus.on("sidepage_hide", () => {
  body.classList.remove("sidepage_open");
});

eventbus.on("site-header_button_active", () => {
  body.classList.add("button-menu_open");
});
eventbus.on("site-header_button_inactive", () => {
  body.classList.remove("button-menu_open");
});

////////////////////////////////////////////////////////
// Toggle scroll when mobile-filter-menu opens/closes //
////////////////////////////////////////////////////////

eventbus.on("mobile-filter-menu_show", () => {
  body.classList.add("disable_scroll");
});
eventbus.on("mobile-filter-menu_hide", () => {
  body.classList.remove("disable_scroll");
});

//////////////////////////////////////////////
// Disable/enable scroll via event-listener //
//////////////////////////////////////////////

const disable_scroll = (e) => {
  e.preventDefault();
};

eventbus.on("cart_open", () => {
  document.addEventListener("mousewheel", disable_scroll, { passive: false });
});
eventbus.on("cart_close", () => {
  document.removeEventListener("mousewheel", disable_scroll, { passive: false });
});

eventbus.on("product-viewer_show", () => {
  document.addEventListener("mousewheel", disable_scroll, { passive: false });
});
eventbus.on("product-viewer_hidden", () => {
  document.removeEventListener("mousewheel", disable_scroll, { passive: false });
});
