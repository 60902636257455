import Component from "gia/Component";
import eventbus from "gia/eventbus";
import config from "gia/config";

import ColorThief from "colorthief/dist/color-thief";

import { toggle_state_element_PROTO } from "../../utilities/toggle_element_state_PROTO";

//////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////

class gridItem_muuri extends Component {
  constructor(element) {
    super(element);
    //////
    this.ref = {
      overlay: null,
      thumb: null,
    };
    //////
    this.options = {
      blockName: "grid-item_muuri",
      //////
      price: "defaultValue",
      title: "defaultValue",
      title_full: "defaultValue",
      gender: "defaultValue",
      info_sizing: "defaultValue",
      size: "defaultValue",
      thumb_org_src: "defaultValue",
      color_custom: "defaultValue",
    };
  }

  mount() {
    config.set("log", false);

    ////////////////////////////////////
    ////////////////////////////////////

    this.setState({
      buyButton_ready: false,
      is_inCart: false,
    });

    //////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////

    const compEl = this.element;
    const refs = this.ref;
    const options = this.options;

    ///////////////////////
    // Set overlay-color //
    ///////////////////////

    const overlay = refs.overlay;
    const item_color_costum = options.color_custom;

    if (item_color_costum === undefined || item_color_costum.length === 0) {
      const item_color_preset = compEl.dataset.color;

      if (item_color_preset === undefined || item_color_preset.length === 0) {
        ///////////////////////////////////////////////////
        // Check if color already stored in loc. storage //
        ///////////////////////////////////////////////////

        let item_color_isStored = false;
        let item_color_stored = "";

        if (window.localStorage.getItem("colors") != null) {
          const item_title = options.title;
          const colors_stored = JSON.parse(window.localStorage.getItem("colors"));
          //////
          for (let color of Object.entries(colors_stored)) {
            const color_item_title = color[1].item;
            //////
            if (item_title === color_item_title) {
              item_color_isStored = true;
              item_color_stored = color[1].hex;
            }
          }
        }
        //////
        if (item_color_isStored) {
          overlay.style.backgroundColor = item_color_stored;
          //////
          const item_color_stored_rgb = this.convert_hex_to_rgb(item_color_stored);
          //////
          if (this.check_color_isBright(item_color_stored_rgb)) {
            this.setState({
              has_bightBG: true,
            });
          }
        } else {
          const colorThief = new ColorThief();
          const thumb_org_src = options.thumb_org_src;
          const thumb_proxy = new Image();
          //////
          thumb_proxy.addEventListener("load", () => {
            const palette = colorThief.getPalette(thumb_proxy);
            //////
            const palette_color_1 = palette[0];
            const palette_color_2 = palette[1];
            //////
            let color_new;
            //////
            if ((palette_color_1[0] > 200, palette_color_1[1] > 200, palette_color_1[2] > 200)) {
              color_new = palette_color_2;
            } else {
              color_new = palette_color_1;
            }
            //////
            if ((color_new[0] > 205, color_new[1] > 205, color_new[2] > 205)) {
              this.setState({
                color: color_new,
                has_bightBG: true,
              });
            } else {
              this.setState({
                color: color_new,
              });
            }
          });
          //////
          let googleProxyURL =
            "https://images1-focus-opensocial.googleusercontent.com/gadgets/proxy?container=focus&refresh=2592000&url=";
          thumb_proxy.crossOrigin = "Anonymous";
          thumb_proxy.src = googleProxyURL + encodeURIComponent(thumb_org_src);
        }
      } else {
        overlay.style.backgroundColor = item_color_preset;
        //////
        const item_color_preset_rgb = this.convert_hex_to_rgb(item_color_preset);
        //////
        if (this.check_color_isBright(item_color_preset_rgb)) {
          this.setState({
            has_bightBG: true,
          });
        }
      }
    } else {
      overlay.style.backgroundColor = "#" + item_color_costum;
      //////
      const item_color_costum_rgb = this.convert_hex_to_rgb(item_color_costum);
      //////
      if (this.check_color_isBright(item_color_costum_rgb)) {
        this.setState({
          has_bightBG: true,
        });
      }
    }

    ///////////////////////////////
    // Config. mouse-interaction //
    ///////////////////////////////

    compEl.addEventListener("mouseenter", () => {
      this.setState({
        overlay_hidden: false,
      });
      //////
      const args = {
        item: {
          thumb: {
            src: options.thumb_org_src,
          },
        },
      };
      //////
      eventbus.emit("gridItem_mouseEnter", args);
    });
    compEl.addEventListener("mouseleave", () => {
      this.setState({
        overlay_hidden: true,
      });
    });

    ////////////////////////////////////////////
    // Config. click (call to product-viewer) //
    ////////////////////////////////////////////

    compEl.addEventListener("click", () => {
      if (window.innerWidth < 700) {
        if (!this.state.buyButton_ready) {
          eventbus.once("gridItem_buyButton_ready", () => {
            this.setState({
              buyButton_ready: true,
            });
            eventbus.emit("gridItem_click", this.create_args(options));
          });
        } else {
          eventbus.emit("gridItem_click", this.create_args(options));
        }
      } else {
        eventbus.emit("gridItem_click", this.create_args(options));
      }
    });

    //////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////

    eventbus.on("cart_itemAdded", (args) => {
      if (args.title === this.options.title) {
        this.setState({
          is_inCart: true,
        });
      }
    });

    eventbus.on("cart_itemRemoved", (args) => {
      if (args.title === this.options.title) {
        this.setState({
          is_inCart: false,
        });
      }
    });
  }

  //////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////

  stateChange(changes) {
    const compEl = this.element;
    const refs = this.ref;
    const options = this.options;
    const state = this.state;

    const blockName = options.blockName;

    ////////////////////////////////////
    ////////////////////////////////////

    if ("overlay_hidden" in changes) {
      const overlay = refs.overlay;
      //////
      if (state.overlay_hidden) {
        toggle_state_element_PROTO(overlay, blockName, "overlay", "hidden", "add");
      } else {
        toggle_state_element_PROTO(overlay, blockName, "overlay", "hidden", "remove");
      }
    }

    ////////////////////////////////////
    ////////////////////////////////////

    if ("is_inCart" in changes) {
      if (state.is_inCart) {
        toggle_state_element_PROTO(compEl, blockName, "", "disabled", "add");
      } else {
        toggle_state_element_PROTO(compEl, blockName, "", "disabled", "remove");
      }
    }

    ////////////////////////////////////
    ////////////////////////////////////

    if ("has_bightBG" in changes) {
      if (state.has_bightBG) {
        compEl.classList.add(blockName + "_color_black");
      }
    }

    ////////////////////////////////////
    ////////////////////////////////////

    if ("color" in changes) {
      const rgbToHex = (r, g, b) =>
        "#" +
        [r, g, b]
          .map((x) => {
            const hex = x.toString(16);
            return hex.length === 1 ? "0" + hex : hex;
          })
          .join("");

      const color = state.color;
      const color_hex = rgbToHex(color[0], color[1], color[2]);
      const overlay = refs.overlay;
      overlay.style.backgroundColor = color_hex;

      ///////////////////////////////////////////////////////////////////
      // Store color in loc. storage (for better perf. on page-reload) //
      ///////////////////////////////////////////////////////////////////

      if (window.localStorage.getItem("colors") != null) {
        const colors_stored = JSON.parse(window.localStorage.getItem("colors"));
        const colors_stored_count = Object.keys(colors_stored).length;
        //////
        const colors_new = Object.assign(colors_stored);
        colors_new["color_" + (colors_stored_count + 1)] = { item: options.title, hex: color_hex };
        //////
        window.localStorage.setItem("colors", JSON.stringify(colors_new));
      } else {
        const colors_new = {};
        colors_new["color_" + 1] = { item: options.title, hex: color_hex };
        //////
        window.localStorage.setItem("colors", JSON.stringify(colors_new));
      }
    }
  }

  //////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////

  get_button() {
    const button = this.element.querySelector(".shopify-buy__btn");
    return button;
  }

  ////////////////////////////////////
  ////////////////////////////////////

  create_args(options) {
    let args = {};
    //////
    const size = options.size;
    //////
    if (size != "Default Title") {
      if (window.innerWidth > 700) {
        args = {
          item: {
            title_full: options.title_full,
            size: options.size,
            gender: options.gender,
            price: options.price,
            description: options.title_full + ", " + options.size + ", " + options.price + " €",
            info_sizing: options.info_sizing,
            button: this.get_button(),
          },
        };
      } else {
        args = {
          item: {
            title_full: options.title_full,
            size: options.size,
            gender: options.gender,
            price: options.price,
            description: options.title_full + ", </br>" + options.size + ", " + options.price + " €",
            info_sizing: options.info_sizing,
            button: this.get_button(),
          },
        };
      }
    } else {
      if (window.innerWidth > 700) {
        args = {
          item: {
            title_full: options.title_full,
            size: options.size,
            gender: options.gender,
            price: options.price,
            description: options.title_full + ", " + options.price + " €",
            info_sizing: options.info_sizing,
            button: this.get_button(),
          },
        };
      } else {
        args = {
          item: {
            title_full: options.title_full,
            size: options.size,
            gender: options.gender,
            price: options.price,
            description: options.title_full + ", </br>" + options.price + " €",
            info_sizing: options.info_sizing,
            button: this.get_button(),
          },
        };
      }
    }
    //////
    return args;
  }

  ////////////////////////////////////
  ////////////////////////////////////

  convert_hex_to_rgb(hex) {
    var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result
      ? {
          r: parseInt(result[1], 16),
          g: parseInt(result[2], 16),
          b: parseInt(result[3], 16),
        }
      : null;
  }

  ////////////////////////////////////
  ////////////////////////////////////

  check_color_isBright(color) {
    return color.r > 195 && color.g > 195 && color.b > 195;
  }
}

//////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////

export { gridItem_muuri };
