import Component from "gia/Component";
import eventbus from "gia/eventbus";
import config from "gia/config";

import { toggle_mod } from "../../utilities/toggle_mod";

//////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////

class pageCover extends Component {
  constructor(element) {
    super(element);
    //////
    this.ref = {};
    //////
    this.options = {
      blockName: "page-cover",
    };
  }

  mount() {
    config.set("log", false);

    ////////////////////////////////////
    // Build main & reference objects //
    ////////////////////////////////////

    this.setState({
      main: {
        element: this.element,
        name_block: this.options.blockName,
        name_element: "",
      },
    });

    //////////////////////////
    // Config. interactions //
    //////////////////////////

    const compEl = this.element;
    compEl.addEventListener("click", () => {
      eventbus.emit("filter-menu_hide");
      eventbus.emit("cart_close");
    });

    //////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////

    eventbus.on("cart_open", () => {
      this.setState({
        hidden: false,
      });
    });
    eventbus.on("cart_close", () => {
      this.setState({
        hidden: true,
      });
    });

    ////////////////////////////////////
    ////////////////////////////////////

    eventbus.on("filter-menu_show", () => {
      this.setState({
        hidden: false,
      });
    });

    eventbus.on("filter-menu_hide", () => {
      this.setState({
        hidden: true,
      });
    });
  }

  //////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////

  stateChange(changes) {
    const state = this.state;
    const options = this.options;

    const main = state.main;
    const refs = state.refs;

    const blockName = options.blockName;

    ////////////////////////////////////
    ////////////////////////////////////

    if ("hidden" in changes) {
      if (state.hidden) {
        toggle_mod(main, "hidden", "add");
      } else {
        toggle_mod(main, "hidden", "remove");
      }
    }
  }

  //////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////
}

//////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////

export { pageCover };
