import Component from "gia/Component";
import eventbus from "gia/eventbus";
import config from "gia/config";

import { toggle_mod } from "../../utilities/toggle_mod";
import { create_refs } from "../../utilities/create_refs";
import { create_main } from "../../utilities/create_main";

////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////

class productViewer extends Component {
  constructor(element) {
    super(element);
    //////
    this.ref = {
      image: null,
      description: null,
      description__title: null,
      description__meta: null,
      //////
      info_sizing: null,
      button_close: null,
      button_buy: null,
    };
    //////
    this.options = {
      blockName: "product-viewer",
    };
  }

  mount() {
    config.set("log", false);

    const options = this.options;
    const ref = this.ref;

    /////////////////////////////////
    // Config. main- & ref-objects //
    /////////////////////////////////

    this.setState({
      main: create_main(this.element, options.blockName),
      refs: create_refs(ref, options.blockName),
    });

    //////////////////////////
    // Config. interactions //
    //////////////////////////

    ref.button_close.addEventListener("click", () => {
      this.hide();
    });

    ref.button_buy.addEventListener("click", () => {
      this.state.button.element.click();
    });

    ////////////////////////////////
    // Config. eventbus responses //
    ////////////////////////////////

    eventbus.on("gridItem_mouseEnter", (args) => {
      this.setState({
        image: {
          src: args.item.thumb.src,
        },
      });
    });

    ////////////////////////////////////
    ////////////////////////////////////

    eventbus.on("gridItem_click", (args) => {
      this.show();
      console.log(args);
      //////
      this.setState({
        title_full: args.item.title_full,
        size: args.item.size,
        gender: args.item.gender,
        price: args.item.price,
        description: args.item.description,
        info_sizing: args.item.info_sizing,
        button: { element: args.item.button },
      });
    });

    ////////////////////////////////////
    ////////////////////////////////////

    eventbus.on("cart_itemAdded", () => {
      this.setState({
        hidden: true,
      });
    });

    //////////////////////////////////////
    // Update height on window resizing //
    //////////////////////////////////////

    eventbus.on("window_resize", (args) => {
      this.setState({
        height: args.window.height,
      });
    });
  }

  //////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////

  stateChange(changes) {
    const state = this.state;
    const options = this.options;

    const main = state.main;
    const refs = state.refs;

    const blockName = options.blockName;

    ////////////////////////////////////
    ////////////////////////////////////

    if ("hidden" in changes) {
      if (state.hidden) {
        main.hide();
        eventbus.emit("product-viewer_hidden");
      } else {
        this.setState({
          height: window.innerHeight,
        });
        //////
        main.show();
        eventbus.emit("product-viewer_show");
      }
    }

    ////////////////////////////////////
    ////////////////////////////////////

    if ("description" in changes) {
      const description__title = this.get_ref("description__title");
      const description__meta = this.get_ref("description__meta");
      //////
      description__title.element.innerHTML = state.title_full;
      //////
      if (state.size != "Default Title") {
        description__meta.element.innerHTML = state.size + ", " + state.gender + ", " + state.price;
      } else {
        description__meta.element.innerHTML = state.gender + ", " + state.price;
      }
    }

    ////////////////////////////////////
    ////////////////////////////////////

    if ("info_sizing" in changes) {
      const info_sizingEl = refs.find((ref) => ref.name_element === "info_sizing");
      info_sizingEl.element.querySelector("span").innerHTML = state.info_sizing;
    }

    ////////////////////////////////////
    ////////////////////////////////////

    if ("image" in changes) {
      const image = refs.find((ref) => ref.name_element === "image");
      image.element.src = state.image.src;
    }

    ////////////////////////////////////
    ////////////////////////////////////

    if ("height" in changes) {
      main.element.style.height = state.height + "px";
    }
  }

  //////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////

  hide() {
    this.setState({
      hidden: true,
    });
  }

  ////////////////////////////////////
  ////////////////////////////////////

  show() {
    this.setState({
      hidden: false,
    });
  }

  ////////////////////////////////////
  ////////////////////////////////////

  get_ref(title) {
    return this.state.refs.find((ref) => ref.name_element === title);
  }
}

//////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////

export { productViewer };
