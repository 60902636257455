import Component from "gia/Component";
import eventbus from "gia/eventbus";
import config from "gia/config";

import { toggle_state_element_PROTO } from "../../utilities/toggle_element_state_PROTO";

//////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////

class counter extends Component {
  constructor(element) {
    super(element);
    //////
    this.ref = {
      countEl: null,
    };
    //////
    this.options = {
      className: "counter",
    };
  }

  mount() {
    config.set("log", false);

    /////////////////////////////////
    /////////////////////////////////

    this.setState({});

    //////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////

    const compEl = this.element;
    const refs = this.ref;

    //////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////

    eventbus.on("update_counter", (args) => {
      this.setState({
        count: args.count,
      });
    });
  }

  //////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////

  stateChange(changes) {
    const compEl = this.element;
    const refs = this.ref;
    const options = this.options;
    const state = this.state;

    const className = options.className;

    /////////////////////////////////
    /////////////////////////////////

    if ("count" in changes) {
      refs.countEl.innerHTML = state.count;
    }
  }

  //////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////
}

//////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////

export { counter };
