import Component from "gia/Component";
import eventbus from "gia/eventbus";
import config from "gia/config";

import { toggle_mod } from "../../utilities/toggle_mod";
import { create_refs } from "../../utilities/create_refs";
import { create_main } from "../../utilities/create_main";

//////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////

class mobileFilterMenu__entry extends Component {
  constructor(element) {
    super(element);
    //////
    this.ref = {
      header: null,
      button_open: null,
      content: null,
      filterButtons: [],
    };
    //////
    this.options = {
      blockName: "mobile-filter-menu",
      id: "defaultValue",
    };
  }

  mount() {
    config.set("log", false);

    const options = this.options;
    const ref = this.ref;

    /////////////////////////////////
    // Config. main- & ref-objects //
    /////////////////////////////////

    this.setState({
      main: create_main(this.element, options.blockName),
      refs: create_refs(ref, options.blockName),
      //////
      open: false,
      filterButtons_active: [],
    });

    //////////////////////////
    // Config. interactions //
    //////////////////////////

    const state = this.state;
    const main = state.main;
    const refs = state.refs;

    const header = ref.header;
    header.addEventListener("click", () => {
      if (this.state.open) {
        this.setState({
          open: false,
        });
      } else {
        this.setState({
          open: true,
        });
        eventbus.emit("mobile-filter-menu_open", { id: options.id });
      }
    });

    ////////////////////////////////
    // Config. eventbus responses //
    ////////////////////////////////

    eventbus.on("mobile-filter-menu_hide", () => {
      this.setState({
        open: false,
      });
    });

    eventbus.on("mobile-filter-menu_open", (args) => {
      const id = args.id;
      if (id != options.id) {
        this.setState({
          open: false,
        });
      }
    });

    //////////////////////////////////////
    // Listen for remote activations of //
    // filter-buttons to track active   //
    //////////////////////////////////////

    eventbus.on("filter-button_click", (args) => {
      const filterButtons = ref.filterButtons;
      for (let filterButton of filterButtons) {
        if (filterButton.dataset.filter === args.filter) {
          const filterButtons_active = this.state.filterButtons_active;
          let filterButtons_active_new = [...filterButtons_active];
          //////
          if (!filterButtons_active.includes(filterButton)) {
            filterButtons_active_new.push(filterButton);
            this.setState({
              filterButtons_active: filterButtons_active_new,
            });
          } else {
            filterButtons_active_new.splice(filterButtons_active_new.indexOf(filterButton), 1);
            this.setState({
              filterButtons_active: filterButtons_active_new,
            });
          }
          //////
          const entryIsActive = this.state.filterButtons_active.length > 0;
          if (entryIsActive) {
            this.setState({
              active: true,
            });
          } else {
            this.setState({
              active: false,
            });
          }
        }
      }
    });
  }

  //////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////

  ////////////////////////////////////
  // Config. state-change responses //
  ////////////////////////////////////

  stateChange(changes) {
    const state = this.state;
    const options = this.options;

    const main = state.main;
    const refs = state.refs;

    const blockName = options.blockName;

    /////////////////////////////////
    /////////////////////////////////

    if ("active" in changes) {
      const header = refs.find((ref) => ref.name_element === "header");
      if (state.active) {
        header.element.classList.add(blockName + "__entry-header_active");
      } else {
        header.element.classList.remove(blockName + "__entry-header_active");
      }
    }

    /////////////////////////////////
    /////////////////////////////////

    if ("open" in changes) {
      const header = refs.find((ref) => ref.name_element === "header");
      const button_open = refs.find((ref) => ref.name_element === "button_open");
      const content = refs.find((ref) => ref.name_element === "content");
      //////
      if (state.open) {
        button_open.element.classList.add(blockName + "__button_open_active");
        content.element.classList.remove(blockName + "__entry-content_hidden");
        //////
        header.element.classList.add(blockName + "__entry-header_open");
      } else {
        button_open.element.classList.remove(blockName + "__button_open_active");
        content.element.classList.add(blockName + "__entry-content_hidden");
        //////
        header.element.classList.remove(blockName + "__entry-header_open");
      }
    }
  }

  //////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////
}

//////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////

export { mobileFilterMenu__entry };
