import Component from "gia/Component";
import eventbus from "gia/eventbus";
import config from "gia/config";

import { toggle_mod } from "../../../utilities/toggle_mod";
import { create_refs } from "../../../utilities/create_refs";
import { create_main } from "../../../utilities/create_main";

//////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////

class siteHeader__selectButton extends Component {
  constructor(element) {
    super(element);
    //////
    this.ref = {};
    //////
    this.options = {
      blockName: "site-header",
      //////
      id: "defaultValue",
    };
  }

  mount() {
    config.set("log", false);

    const options = this.options;
    const ref = this.ref;

    /////////////////////////////////
    // Config. main- & ref-objects //
    /////////////////////////////////

    // const main_methods_custom = [
    //   {
    //     method_type: "modifier",
    //     method_names: ["stick", "unstick"],
    //     method_modifier: "sticky",
    //   },
    // ];

    this.setState({
      main: create_main(this.element, options.blockName),
      refs: create_refs(ref, options.blockName),
    });

    //////////////////////////
    // Config. interactions //
    //////////////////////////

    //////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////

    eventbus.on("mouseEnter_siteHeader-button", (args) => {
      if (args.id != options.id) {
        this.setState({
          disabled: true,
        });
      } else {
        this.setState({
          disabled: false,
        });
      }
    });

    /////////////////////////////////
    /////////////////////////////////

    eventbus.on("mouseLeave_siteHeader-button", () => {
      this.setState({
        disabled: false,
      });
    });
  }

  //////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////

  stateChange(changes) {
    const state = this.state;
    const options = this.options;

    const main = state.main;
    const refs = state.refs;

    const blockName = options.blockName;

    /////////////////////////////////
    /////////////////////////////////

    if ("disabled" in changes) {
      if (state.disabled) {
        main.element.classList.add(blockName + "__button_hidden");
      } else {
        main.element.classList.remove(blockName + "__button_hidden");
      }
    }
  }

  //////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////
}

//////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////

export { siteHeader__selectButton };
