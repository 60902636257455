import Component from "gia/Component";
import eventbus from "gia/eventbus";
import config from "gia/config";

import Swiper, { Autoplay, Navigation } from "swiper/core";
Swiper.use([Autoplay, Navigation]);

import { toggle_mod } from "../../utilities/toggle_mod";
import { create_refs } from "../../utilities/create_refs";
import { create_main } from "../../utilities/create_main";

//////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////

class sidepage__slider extends Component {
  constructor(element) {
    super(element);
    //////
    this.ref = {
      container: null,
      button_prev: null,
      button_next: null,
      count_current: null,
    };
    //////
    this.options = {
      blockName: "sidepage__slider",
      //////
      speed: 600,
      delay: 3000,
    };
  }

  mount() {
    config.set("log", false);

    const options = this.options;
    const ref = this.ref;

    /////////////////////////////////
    // Config. main- & ref-objects //
    /////////////////////////////////

    // const main_methods_custom = [
    //   {
    //     method_type: "modifier",
    //     method_names: ["stick", "unstick"],
    //     method_modifier: "sticky",
    //   },
    // ];

    this.setState({
      main: create_main(this.element, options.blockName),
      refs: create_refs(ref, options.blockName),
    });

    ////////////////////////
    // Build inst. //
    ////////////////////////

    const state = this.state;
    const main = state.main;
    const refs = state.refs;

    const container = refs.find((ref) => ref.name_element === "container");

    const slider = new Swiper(container.element, {
      init: false,
      allowTouchMove: false,
      //////
      speed: options.speed,
      direction: "horizontal",
      loop: true,
      autoplay: {
        delay: options.delay,
        disableOnInteraction: false,
      },
      //////
      navigation: {
        nextEl: ref.button_next,
        prevEl: ref.button_prev,
      },
      //////
      on: {
        slideChange: () => {
          const index_slide_active = slider.realIndex;
          this.setState({
            index_active: index_slide_active + 1,
          });
        },
      },
    });

    /////////////////
    // Init. inst. //
    /////////////////

    this.setState({
      inst: { slider },
      initialised: true,
    });

    //////////////////////////
    // Config. interactions //
    //////////////////////////

    ////////////////////////////////
    // Config. eventbus responses //
    ////////////////////////////////
  }

  //////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////

  ////////////////////////////////////
  // Config. state-change responses //
  ////////////////////////////////////

  stateChange(changes) {
    const state = this.state;
    const options = this.options;

    const main = state.main;
    const refs = state.refs;

    const blockName = options.blockName;

    /////////////////////////////////
    /////////////////////////////////

    if ("initialised" in changes) {
      if (state.initialised) {
        const slider = state.inst.slider;
        slider.init();
      }
    }

    /////////////////////////////////
    /////////////////////////////////

    if ("index_active" in changes) {
      const count_current = refs.find((ref) => ref.name_element === "count_current");
      count_current.element.innerHTML = state.index_active;
    }
  }

  //////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////
}

//////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////

export { sidepage__slider };
