import Component from "gia/Component";
import eventbus from "gia/eventbus";
import loadComponents from "gia/loadComponents";
import config from "gia/config";

import Muuri from "muuri";
import lozad from "lozad";
import ColorThief from "colorthief/dist/color-thief";

import { gridItem_muuri } from "../grid-item/gridItem_muuri";

//////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////

class grid_muuri extends Component {
  constructor(element) {
    super(element);
    //////
    this.ref = {
      items: [],
    };
    //////
    this.options = {
      className: "grid_muuri",
    };
  }

  mount() {
    config.set("log", false);

    /////////////////////////////////
    /////////////////////////////////

    this.setState({
      initialised: false,
      columns: 4,
      //////
      filters_price: {
        min: 0,
        max: 999,
      },
      //////
      filters_color: ["all"],
      filters_size: ["all"],
      filters_line: ["all"],
      filters_type: ["all"],
      filters_gender: ["all"],
      filters_heel: ["all"],
      filters_style: ["all"],
      filters_extra: ["all"],
    });

    ////////////////////////////////////////////////////////////////////////
    ////////////////////////////////////////////////////////////////////////

    const compEl = this.element;
    const refs = this.ref;

    ///////////////////////////////////////
    // Send thumbnail URLs to cart-comp. //
    ///////////////////////////////////////

    for (let item of this.ref.items) {
      eventbus.emit("grid_itemInitialised", {
        thumbnail: {
          handle: item.dataset.title.replace(/\s+/g, "-").toLowerCase(),
          src: item.dataset.thumb,
          altText: "null",
        },
      });

      ///////////////////////////////////////////////////////////
      // Dyn. load grid-item comp. on mouse-enter on item-ref. //
      ///////////////////////////////////////////////////////////

      item.addEventListener("mouseenter", () => {
        if (!item.hasAttribute("data-initialised")) {
          const itemCompEl = item.querySelector(".grid-item_muuri__item-content");
          //////
          itemCompEl.setAttribute("g-component", itemCompEl.dataset.gComponent);
          item.setAttribute("data-initialised", "");
          //////
          loadComponents({ gridItem_muuri: gridItem_muuri }, item);
          this.create_button(item.querySelector(".grid-item_muuri__overlay"), item.dataset.product);
        }
      });
    }

    ////////////////////////////
    // Build + init. instance //
    ////////////////////////////

    const grid_inst = new Muuri(compEl);
    grid_inst.on("layoutEnd", () => {
      if (!this.state.initialised) {
        this.setState({
          initialised: true,
        });
      }
    });

    this.setState({
      grid: { grid_inst },
    });

    /////////////////////////////////
    // Build + init. Shopify inst. //
    /////////////////////////////////

    const client = ShopifyBuy.buildClient({
      domain: "singles-and-samples.myshopify.com",
      storefrontAccessToken: "a089bb0caca1bc6b0a90691add6fa23b",
      appId: "6",
    });

    this.setState({
      shopify_options: {
        product: {
          iframe: false,
          text: {
            button: "",
            outOfStock: "",
          },
          contents: {
            price: false,
            title: false,
            variantTitle: false,
            description: false,
            buttonWithQuantity: false,
            quantity: false,
            img: false,
            imgWithCarousel: false,
          },
          events: {
            beforeInit: (ITEM) => {
              // Set Shopify product instance to available variant...
              const variantId = ITEM.node.dataset.variantId;
              ITEM.defaultStorefrontVariantId = `gid://shopify/ProductVariant/${variantId}`;
            },
            afterRender: () => {
              eventbus.emit("gridItem_buyButton_ready");
            },
          },
        },

        //////
        cart: {
          iframe: false,
          startOpen: false,
          popup: true,
        },
        //////
        lineItem: {},
        //////
        toggle: {
          iframe: false,
          contents: {
            title: false,
            icon: false,
          },
        },
        modalProduct: {
          contents: {},
        },
        productSet: {},
      },
      shopify_client: client,
    });

    ////////////////////////////////
    // Create cart + attach comp. //
    ////////////////////////////////

    this.create_cart();

    /////////////////////////////////////////
    // Build + init. lazy-loading observer //
    /////////////////////////////////////////

    const colorThief = new ColorThief();
    const rgbToHex = (r, g, b) =>
      "#" +
      [r, g, b]
        .map((x) => {
          const hex = x.toString(16);
          return hex.length === 1 ? "0" + hex : hex;
        })
        .join("");

    const observer_inst = lozad(".grid-item_muuri_not-initialised", {
      rootMargin: "100px 0px",
      load: (item) => {
        const item_thumb = item.querySelector("img");
        item_thumb.src = item_thumb.getAttribute("data-src");
        item.classList.remove("grid-item_muuri_not-initialised");

        ////////////////////
        // Calc. bg-color //
        ////////////////////

        const thumb_org_src = item_thumb.getAttribute("data-src");
        const thumb_proxy = new Image();
        //////
        thumb_proxy.addEventListener("load", () => {
          const palette = colorThief.getPalette(thumb_proxy);
          //////
          const palette_color_1 = palette[0];
          const palette_color_2 = palette[1];
          //////
          let color_new;
          //////
          if ((palette_color_1[0] > 200, palette_color_1[1] > 200, palette_color_1[2] > 200)) {
            color_new = palette_color_2;
          } else {
            color_new = palette_color_1;
          }
          //////
          const gridItemComp = item.querySelector(".grid-item_muuri__item-content");
          gridItemComp.setAttribute("data-color", rgbToHex(color_new[0], color_new[1], color_new[2]));
        });
        //////
        let googleProxyURL =
          "https://images1-focus-opensocial.googleusercontent.com/gadgets/proxy?container=focus&refresh=2592000&url=";
        thumb_proxy.crossOrigin = "Anonymous";
        thumb_proxy.src = googleProxyURL + encodeURIComponent(thumb_org_src);
      },
    });

    this.setState({
      observer: { observer_inst },
    });

    ////////////////////////////////////////////////////////////////////////
    ////////////////////////////////////////////////////////////////////////

    eventbus.on("update_grid_columns", (args) => {
      const columns_current = this.state.columns;
      //////
      if (args.increase) {
        if (columns_current >= 3 && columns_current <= 5) {
          this.setState({
            columns: columns_current - 1,
          });
        }
      } else {
        if (columns_current >= 2 && columns_current <= 4) {
          this.setState({
            columns: columns_current + 1,
          });
        }
      }
    });

    ////////////////////////////////////
    ////////////////////////////////////

    eventbus.on("update_filters_color", (args) => {
      const filter = args.filter;
      const mode = args.mode;
      //////
      const filters_color_current = this.state.filters_color;
      let filters_color_new = [...filters_color_current];
      //////
      if (mode === "add") {
        filters_color_new.push(filter);
      } else {
        filters_color_new.splice(filters_color_new.indexOf(filter), 1);
      }
      //////
      this.setState({
        filters_color: filters_color_new,
      });
    });

    /////////
    /////////

    eventbus.on("update_filters_size", (args) => {
      const filter = args.filter;
      const mode = args.mode;
      //////
      const filters_size_current = this.state.filters_size;
      let filters_size_new = [...filters_size_current];
      //////
      if (mode === "add") {
        filters_size_new.push(filter);
      } else {
        filters_size_new.splice(filters_size_new.indexOf(filter), 1);
      }
      //////
      this.setState({
        filters_size: filters_size_new,
      });
    });

    /////////
    /////////

    eventbus.on("update_filters_line", (args) => {
      const filter = args.filter;
      const mode = args.mode;
      //////
      const filters_line_current = this.state.filters_line;
      let filters_line_new = [...filters_line_current];
      //////
      if (mode === "add") {
        filters_line_new.push(filter);
      } else {
        filters_line_new.splice(filters_line_new.indexOf(filter), 1);
      }
      //////
      this.setState({
        filters_line: filters_line_new,
      });
    });

    /////////
    /////////

    eventbus.on("update_filters_type", (args) => {
      const filter = args.filter;
      const mode = args.mode;
      //////
      const filters_type_current = this.state.filters_type;
      let filters_type_new = [...filters_type_current];
      //////
      if (mode === "add") {
        filters_type_new.push(filter);
      } else {
        filters_type_new.splice(filters_type_new.indexOf(filter), 1);
      }
      //////
      this.setState({
        filters_type: filters_type_new,
      });
    });

    /////////
    /////////

    eventbus.on("update_filters_gender", (args) => {
      const filter = args.filter;
      const mode = args.mode;
      //////
      const filters_gender_current = this.state.filters_gender;
      let filters_gender_new = [...filters_gender_current];
      //////
      if (mode === "add") {
        filters_gender_new.push(filter);
      } else {
        filters_gender_new.splice(filters_gender_new.indexOf(filter), 1);
      }
      //////
      this.setState({
        filters_gender: filters_gender_new,
      });
      //////
      if (filters_gender_new.length > 1) {
        eventbus.emit("grid_filters_gender_active");
      } else {
        eventbus.emit("grid_filters_gender_inactive");
      }
    });

    /////////
    /////////

    eventbus.on("update_filters_heel", (args) => {
      const filter = args.filter;
      const mode = args.mode;
      //////
      const filters_heel_current = this.state.filters_heel;
      let filters_heel_new = [...filters_heel_current];
      //////
      if (mode === "add") {
        filters_heel_new.push(filter);
      } else {
        filters_heel_new.splice(filters_heel_new.indexOf(filter), 1);
      }
      //////
      this.setState({
        filters_heel: filters_heel_new,
      });
    });

    /////////
    /////////

    eventbus.on("update_filters_style", (args) => {
      const filter = args.filter;
      const mode = args.mode;
      //////
      const filters_style_current = this.state.filters_style;
      let filters_style_new = [...filters_style_current];
      //////
      if (mode === "add") {
        filters_style_new.push(filter);
      } else {
        filters_style_new.splice(filters_style_new.indexOf(filter), 1);
      }
      //////
      this.setState({
        filters_style: filters_style_new,
      });
    });

    /////////
    /////////

    eventbus.on("update_filters_extra", (args) => {
      const filter = args.filter;
      const mode = args.mode;
      //////
      const filters_extra_current = this.state.filters_extra;
      let filters_extra_new = [...filters_extra_current];
      //////
      if (mode === "add") {
        filters_extra_new.push(filter);
      } else {
        filters_extra_new.splice(filters_extra_new.indexOf(filter), 1);
      }
      //////
      this.setState({
        filters_extra: filters_extra_new,
      });
    });

    //////////////////
    //////////////////

    eventbus.on("update_filters_price", (args) => {
      this.setState({
        filters_price: {
          min: args.price.min,
          max: args.price.max,
        },
      });
    });

    //////////////////
    //////////////////

    eventbus.on("grid_show-all", () => {
      this.setState({
        filters_price: {
          min: 0,
          max: 999,
        },
        filters_color: ["all"],
        filters_size: ["all"],
        filters_line: ["all"],
        filters_type: ["all"],
        filters_gender: ["all"],
        filters_heel: ["all"],
        filters_style: ["all"],
        filters_extra: ["all"],
      });
    });
  }

  ////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  stateChange(changes) {
    const compEl = this.element;
    const refs = this.ref;
    const options = this.options;
    const state = this.state;

    const className = options.className;

    ////////////////////////////////////
    ////////////////////////////////////

    if ("initialised" in changes) {
      if (state.initialised) {
        this.setState({
          count_items: refs.items.length,
          count_items_active: refs.items.filter((item) => {
            return item.classList.contains("muuri-item-shown");
          }).length,
        });

        /////////////////////////////////
        // Init. lazy-loading observer //
        /////////////////////////////////

        state.observer.observer_inst.observe();
      }
    }

    ////////////////////////////////////
    ////////////////////////////////////

    if ("count_items_active" in changes) {
      eventbus.emit("grid_update_items_active", { count_items_active: state.count_items_active });
    }

    ////////////////////////////////////
    ////////////////////////////////////

    if ("columns" in changes && state.initialised) {
      const grid_columns = state.columns;
      const items = refs.items;
      //////
      for (let item of items) {
        const classes_current = item.classList;
        for (let class_current of classes_current) {
          if (class_current.includes("_w_")) {
            item.classList.remove(class_current);
          }
        }
        //////
        if (grid_columns === 2) {
          item.classList.add("grid-item_muuri_w_6");
        } else {
          if (grid_columns === 3) {
            item.classList.add("grid-item_muuri_w_4");
          } else {
            if (grid_columns === 4) {
              item.classList.add("grid-item_muuri_w_3");
            } else {
              if (grid_columns === 5) {
                item.classList.add("grid-item_muuri_w_2-4");
              }
            }
          }
        }
      }
      //////
      const grid = state.grid.grid_inst;
      grid.refreshItems();
      grid.layout();
    }

    ////////////////////////////////////
    ////////////////////////////////////

    if ("filters_color" in changes) {
      if (state.initialised) {
        this.grid_filter();
      }
    }

    //////////////////
    //////////////////

    if ("filters_size" in changes) {
      if (state.initialised) {
        this.grid_filter();
      }
    }

    //////////////////
    //////////////////

    if ("filters_line" in changes) {
      if (state.initialised) {
        this.grid_filter();
      }
    }

    //////////////////
    //////////////////

    if ("filters_type" in changes) {
      if (state.initialised) {
        this.grid_filter();
      }
    }

    //////////////////
    //////////////////

    if ("filters_gender" in changes) {
      if (state.initialised) {
        this.grid_filter();
      }
    }

    //////////////////
    //////////////////

    if ("filters_heel" in changes) {
      if (state.initialised) {
        this.grid_filter();
      }
    }

    //////////////////
    //////////////////

    if ("filters_style" in changes) {
      if (state.initialised) {
        this.grid_filter();
      }
    }

    //////////////////
    //////////////////

    if ("filters_extra" in changes) {
      if (state.initialised) {
        this.grid_filter();
      }
    }

    //////////////////
    //////////////////

    if ("filters_price" in changes && state.initialised) {
      this.grid_filter();
    }
  }

  //////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////

  create_button(node, id) {
    const client = this.state.shopify_client;
    const options = this.state.shopify_options;
    //////
    ShopifyBuy.UI.onReady(client).then(function (ui) {
      ui.createComponent("product", {
        id: [id],
        node: node,
        moneyFormat: "{{amount_with_comma_separator}} €",
        options: options,
      });
    });
  }

  ////////////////////////////////////
  ////////////////////////////////////

  create_cart() {
    const client = this.state.shopify_client;
    const options = this.state.shopify_options;
    //////
    ShopifyBuy.UI.onReady(client).then(function (ui) {
      ui.createComponent("cart", {
        options: options,
      });
    });
  }

  ////////////////////////////////////
  ////////////////////////////////////

  grid_filter() {
    const state = this.state;

    //////////////////////////
    // Set up filter-groups //
    //////////////////////////

    const filters_color = state.filters_color;
    const filters_size = state.filters_size;
    const filters_line = state.filters_line;
    const filters_type = state.filters_type;
    const filters_gender = state.filters_gender;
    const filters_heel = state.filters_heel;
    const filters_style = state.filters_style;
    const filters_extra = state.filters_extra;
    //////
    const filterGroups = [
      { title: "color", values: filters_color },
      { title: "size", values: filters_size },
      { title: "line", values: filters_line },
      { title: "type", values: filters_type },
      { title: "gender", values: filters_gender },
      { title: "heel", values: filters_heel },
      { title: "style", values: filters_style },
      { title: "extra", values: filters_extra },
    ];
    const filterGroups_active = [];
    //////
    for (let group of filterGroups) {
      if (group.values.length > 1) {
        filterGroups_active.push(group);
      }
    }
    ////////////////////////////////////////
    // Get total number of active filters //
    ////////////////////////////////////////

    let count_filters_active = 0;
    for (let group of filterGroups_active) {
      const group__values_valid = group.values.filter((value) => value != "all");
      count_filters_active += group__values_valid.length;
    }
    eventbus.emit("update_count_filters_active", { count_filters_active: count_filters_active });

    ///////////////////////
    // Check curr. price //
    ///////////////////////

    const filters_price = state.filters_price;
    const filters_price_max = filters_price.max;
    const filters_price_min = filters_price.min;

    const filters_price_isActive = filters_price_min > 0 && filters_price_max < 999;

    ///////////////////////
    // Filter grid-inst. //
    ///////////////////////

    const grid = state.grid.grid_inst;

    if (filterGroups_active.length > 0 || filters_price_isActive) {
      grid.filter(
        (item) => {
          const item_el = item.getElement();
          const item_data = Object.assign({}, item_el.dataset);
          //////
          let item_matchesFilters = true;

          //////////////////////////////////////////////
          // Check if item matches all active filters //
          //////////////////////////////////////////////

          for (let group of filterGroups_active) {
            const group_title = group.title;
            const group_filterValues = group.values;
            //////
            const item_data_matchingGroup = item_data[group_title];
            const item_filterValues = item_data_matchingGroup.split(" ");
            //////
            if (!item_filterValues.some((value) => group_filterValues.includes(value))) {
              item_matchesFilters = false;
            }
          }

          //////////////////////////////////////
          // Check if item within price-range //
          //////////////////////////////////////

          const filters_price = state.filters_price;
          const filters_price_max = filters_price.max;
          const filters_price_min = filters_price.min;
          const item_price = parseInt(item_el.dataset.price);

          const item_isNotWithin_min = item_price < filters_price_min;
          const item_isNotWithin_max = item_price > filters_price_max;

          if (item_isNotWithin_min || item_isNotWithin_max) {
            item_matchesFilters = false;
          }

          /////////////////
          // Return item //
          /////////////////

          if (item_matchesFilters) {
            return item;
          }
        },
        {
          onFinish: () => {
            this.update_count_items_active(this.ref.items);
          },
        }
      );
      //////
      if (filterGroups_active.length > 0) {
        eventbus.emit("grid_is_filtered");
      } else {
        eventbus.emit("grid_is_unfiltered");
      }
    } else {
      grid.filter(".grid-item_muuri", {
        onFinish: () => {
          this.update_count_items_active(this.ref.items);
        },
      });
      //////
      eventbus.emit("grid_is_unfiltered");
    }
  }

  ////////////////////////////////////
  ////////////////////////////////////

  update_count_items_active(items) {
    if (this.state.initialised) {
      this.setState({
        count_items_active: items.filter((item) => {
          return item.classList.contains("muuri-item-shown");
        }).length,
      });
    }
  }

  ////////////////////////////////////
  ////////////////////////////////////

  check_item_isInPriceRange(price_item, price) {
    return price_item >= price.min && price_item <= price.max;
  }
}

//////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////

export { grid_muuri };
