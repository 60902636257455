import Component from "gia/Component";
import eventbus from "gia/eventbus";
import config from "gia/config";

import { toggle_mod } from "../../utilities/toggle_mod";
import { create_refs } from "../../utilities/create_refs";
import { create_main } from "../../utilities/create_main";

//////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////

class filterButton extends Component {
  constructor(element) {
    super(element);
    //////
    this.ref = {};
    //////
    this.options = {
      blockName: "filter-button",
      //////
      type: "defaultValue",
      filter: "defaultValue",
      //////
      displayOnly: "defaultValue",
    };
  }

  mount() {
    config.set("log", false);

    const options = this.options;
    const ref = this.ref;

    /////////////////////////////////
    // Config. main- & ref-objects //
    /////////////////////////////////

    this.setState({
      main: create_main(this.element, options.blockName),
      refs: create_refs(ref, options.blockName),
    });

    //////////////////////////
    // Config. interactions //
    //////////////////////////

    this.element.addEventListener("click", () => {
      const type = options.type;
      if (type != "defaultValue") {
        if (this.state.active) {
          eventbus.emit("update_filters_" + options.type, { filter: options.filter, mode: "remove" });
          eventbus.emit("filter-button_click", { filter: options.filter, mode: "remove" });
          //////
          this.setState({
            active: false,
          });
        } else {
          eventbus.emit("update_filters_" + options.type, { filter: options.filter, mode: "add" });
          eventbus.emit("filter-button_click", { filter: options.filter, mode: "add" });
          //////
          this.setState({
            active: true,
          });
        }
      } else {
        console.warn("type-option is not set for this filter-button comp.", this);
      }
    });

    //////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////

    eventbus.on("filter-button_click", (args) => {
      const mode = args.mode;
      //////
      const filter_this = options.filter;
      const filter_clicked = args.filter;
      //////
      if (mode === "remove") {
        if (filter_clicked === filter_this) {
          this.setState({
            active: false,
          });
        }
      } else {
        if (filter_clicked === filter_this) {
          this.setState({
            active: true,
          });
        }
      }
    });

    /////////////////////////////////////////////
    // Disable when Show-all button is clicked //
    /////////////////////////////////////////////

    eventbus.on("grid_show-all", () => {
      this.setState({
        active: false,
      });
    });
  }

  //////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////

  stateChange(changes) {
    const state = this.state;
    const options = this.options;

    const main = state.main;
    const refs = state.refs;

    const blockName = options.blockName;

    ////////////////////////////////////
    ////////////////////////////////////

    if ("active" in changes) {
      const is_displayOnly = options.displayOnly == "true";
      //////
      if (state.active) {
        main.activate();
        //////
        if (is_displayOnly) {
          main.show();
        }
      } else {
        main.deactivate();
        //////
        if (is_displayOnly) {
          main.hide();
        }
      }
    }
  }
}

//////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////

export { filterButton };
