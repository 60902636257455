import Component from "gia/Component";
import eventbus from "gia/eventbus";
import config from "gia/config";

import { toggle_mod } from "../../utilities/toggle_mod";
import { create_refs } from "../../utilities/create_refs";
import { create_main } from "../../utilities/create_main";
import { height } from "dom7";

//////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////

class sidefooter extends Component {
  constructor(element) {
    super(element);
    //////
    this.ref = {
      link_imprint: null,
      link_privacy: null,
      link_about: null,
    };
    //////
    this.options = {
      blockName: "sidefooter",
    };
  }

  mount() {
    config.set("log", false);

    const options = this.options;
    const ref = this.ref;

    /////////////////////////////////
    // Config. main- & ref-objects //
    /////////////////////////////////

    this.setState({
      sidepage_hidden: true,
      main: create_main(this.element, options.blockName),
      refs: create_refs(ref, options.blockName),
    });

    //////////////////////////
    // Config. interactions //
    //////////////////////////

    const state = this.state;
    const main = state.main;
    const refs = state.refs;

    const link_imprint = refs.find((ref) => ref.name_element === "link_imprint");
    const link_privacy = refs.find((ref) => ref.name_element === "link_privacy");
    const link_about = refs.find((ref) => ref.name_element === "link_about");
    const links = [link_imprint, link_privacy, link_about];

    for (let link of links) {
      link.element.addEventListener("click", () => {
        if (this.state.sidepage_hidden) {
          this.setState({
            link_active: link.element.dataset.title,
            sidepage_hidden: false,
          });
        }
      });
    }

    ////////////////////////////////
    // Config. eventbus responses //
    ////////////////////////////////

    eventbus.on("sidepage_hide", () => {
      this.setState({
        link_active: "null",
        sidepage_hidden: true,
      });
    });
  }

  //////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////

  stateChange(changes) {
    const state = this.state;
    const options = this.options;

    const main = state.main;
    const refs = state.refs;

    const blockName = options.blockName;

    /////////////////////////////////
    /////////////////////////////////

    if ("sidepage_hidden" in changes) {
      const link_imprint = refs.find((ref) => ref.name_element === "link_imprint");
      const link_privacy = refs.find((ref) => ref.name_element === "link_privacy");
      const link_about = refs.find((ref) => ref.name_element === "link_about");
      const links = [link_imprint, link_privacy, link_about];
      //////
      if (state.sidepage_hidden) {
        for (let link of links) {
          link.element.classList.remove(blockName + "__link_inactive");
          link.element.classList.remove(blockName + "__link_active");
        }
        //////
        eventbus.emit("sidepage_hide");
      } else {
        eventbus.emit("sidepage_show", { context: state.link_active });
      }
    }

    /////////////////////////////////
    /////////////////////////////////

    if ("link_active" in changes) {
      const link_active = state.link_active;
      //////
      if (link_active != "null") {
        const link_imprint = refs.find((ref) => ref.name_element === "link_imprint");
        const link_privacy = refs.find((ref) => ref.name_element === "link_privacy");
        const link_about = refs.find((ref) => ref.name_element === "link_about");
        const links = [link_imprint, link_privacy, link_about];
        //////
        for (let link of links) {
          if (link.element.dataset.title === link_active) {
            link.element.classList.add(blockName + "__link_active");
            link.element.classList.remove(blockName + "__link_inactive");
          } else {
            link.element.classList.remove(blockName + "__link_active");
            link.element.classList.add(blockName + "__link_inactive");
          }
        }
      }
    }
  }

  //////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////
}

//////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////

export { sidefooter };
