import Component from "gia/Component";
import eventbus from "gia/eventbus";
import config from "gia/config";

import noUiSlider from "nouislider";

import { toggle_mod } from "../../utilities/toggle_mod";
import { create_refs } from "../../utilities/create_refs";
import { create_main } from "../../utilities/create_main";

//////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////

class filterMenu extends Component {
  constructor(element) {
    super(element);
    //////
    this.ref = {
      button_close: null,
      rangeSliderEl: null,
      total: null,
      total_label: null,
      button_showAll: null,
    };
    //////
    this.options = {
      blockName: "filter-menu",
    };
  }

  mount() {
    config.set("log", false);

    const options = this.options;
    const ref = this.ref;

    /////////////////////////////////
    // Config. main- & ref-objects //
    /////////////////////////////////

    this.setState({
      main: create_main(this.element, options.blockName),
      refs: create_refs(ref, options.blockName),
      //////
      hidden: true,
    });

    ///////////////////////////////
    // Create range-slider inst. //
    ///////////////////////////////

    const rangeSliderEl = ref.rangeSliderEl;
    const rangeSlider = noUiSlider.create(rangeSliderEl, {
      start: [parseInt(rangeSliderEl.dataset.min), parseInt(rangeSliderEl.dataset.max)],
      connect: true,
      //////
      step: 10,
      range: {
        min: parseInt(rangeSliderEl.dataset.min) - 60,
        max: parseInt(rangeSliderEl.dataset.max) + 60,
      },
      padding: [60, 60],
      //////
      // format: {
      //   to: function (value) {
      //     return value;
      //   },
      //   from: function (value) {
      //     return Number(value);
      //   },
      // },
    });

    //////////////////////////
    // Config. interactions //
    //////////////////////////

    const state = this.state;
    const main = state.main;
    const refs = state.refs;

    rangeSliderEl.addEventListener("mouseenter", () => {
      this.setState({
        rangeSlider_active: true,
      });
    });
    rangeSliderEl.addEventListener("mouseleave", () => {
      this.setState({
        rangeSlider_active: false,
      });
    });

    ////////////////////////////////////
    ////////////////////////////////////

    const button_close = ref.button_close;
    button_close.addEventListener("click", () => {
      this.setState({
        hidden: true,
      });
    });

    ////////////////////////////////////
    ////////////////////////////////////

    const button_showAll = ref.button_showAll;
    button_showAll.addEventListener("click", () => {
      eventbus.emit("grid_show-all");
      this.setState({
        hidden: true,
      });
    });

    ////////////////////////////////////
    ////////////////////////////////////

    rangeSlider.on("update", () => {
      this.setState({
        price: {
          min: Math.floor(rangeSlider.get()[0]),
          max: Math.floor(rangeSlider.get()[1]),
        },
      });
      //////
      if (!this.state.hidden) {
        eventbus.emit("price-slider_update", {
          id: "filter-menu",
          price: {
            min: Math.floor(rangeSlider.get()[0]),
            max: Math.floor(rangeSlider.get()[1]),
          },
        });
      }
    });

    ////////////////////////////////
    // Config. eventbus responses //
    ////////////////////////////////

    eventbus.on("filter-menu_show", () => {
      this.setState({
        hidden: false,
      });
    });

    eventbus.on("filter-menu_hide", () => {
      this.setState({
        hidden: true,
      });
    });

    ///////////////////////////////////////////
    // Update total amount (of active items) //
    ///////////////////////////////////////////

    eventbus.on("grid_update_items_active", (args) => {
      this.setState({
        count_items_active: args.count_items_active,
      });
    });

    ////////////////////////////////////
    ////////////////////////////////////

    eventbus.on("grid_show-all", () => {
      this.setState({
        price: {
          min: parseInt(options.min),
          max: parseInt(options.max),
        },
      });
      rangeSlider.reset();
    });

    ////////////////////////////////////
    ////////////////////////////////////

    eventbus.on("price-slider_update", (args) => {
      if (args.id != "filter-menu") {
        rangeSlider.set([args.price.min, args.price.max]);
      }
    });
  }

  //////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////

  ////////////////////////////////////
  // Config. state-change responses //
  ////////////////////////////////////

  stateChange(changes) {
    const state = this.state;
    const options = this.options;

    const main = state.main;
    const refs = state.refs;

    const blockName = options.blockName;

    ////////////////////////////////////
    ////////////////////////////////////

    if ("hidden" in changes) {
      if (state.hidden) {
        main.hide();
        eventbus.emit("filter-menu_hide");
      } else {
        main.show();
      }
    }

    ////////////////////////////////////
    ////////////////////////////////////

    // if ("rangeSlider_active" in changes) {
    //   if (state.rangeSlider_active) {
    //     console.log("rangeSlider => active");
    //   } else {
    //     console.log("rangeSlider => not active");
    //   }
    // }

    ////////////////////////////////////
    ////////////////////////////////////

    if ("price" in changes) {
      const rangeSlider_handle_lower = main.element.querySelector(".noUi-handle-lower");
      const rangeSlider_handle_upper = main.element.querySelector(".noUi-handle-upper");
      //////
      rangeSlider_handle_lower.setAttribute("data-value", state.price.min + "€");
      rangeSlider_handle_upper.setAttribute("data-value", state.price.max + "€");
      //////
      eventbus.emit("update_filters_price", { price: state.price });
    }

    /////////////////////////////////
    /////////////////////////////////

    if ("count_items_active" in changes) {
      const count_items_active = state.count_items_active;
      const total = refs.find((ref) => ref.name_element === "total");
      const total_label = refs.find((ref) => ref.name_element === "total_label");
      //////
      total.element.setAttribute("data-value", count_items_active);
      //////
      if (count_items_active === 1) {
        total_label.element.setAttribute("data-label", "pair");
      } else {
        total_label.element.setAttribute("data-label", "pairs");
      }
    }
  }

  //////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////
}

//////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////

export { filterMenu };
