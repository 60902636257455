import Component from "gia/Component";
import eventbus from "gia/eventbus";
import config from "gia/config";

import noUiSlider from "nouislider";

import { toggle_mod } from "../../../utilities/toggle_mod";
import { create_refs } from "../../../utilities/create_refs";
import { create_main } from "../../../utilities/create_main";

//////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////

class siteHeader__rangeSlider extends Component {
  constructor(element) {
    super(element);
    //////
    this.ref = {
      rangeSliderEl: null,
    };
    //////
    this.options = {
      blockName: "site-header__range-slider",
      //////
      min: "defaultValue",
      max: "defaultValue",
    };
  }

  mount() {
    config.set("log", false);

    const options = this.options;
    const ref = this.ref;

    /////////////////////////////////
    // Config. main- & ref-objects //
    /////////////////////////////////

    this.setState({
      main: create_main(this.element, options.blockName),
      refs: create_refs(ref, options.blockName),
    });

    ///////////////////////////////
    // Create range-slider inst. //
    ///////////////////////////////

    const rangeSliderEl = ref.rangeSliderEl;
    const rangeSlider = noUiSlider.create(rangeSliderEl, {
      start: [parseInt(options.min), parseInt(options.max)],
      connect: true,
      //////
      step: 10,
      range: {
        min: parseInt(options.min) - 0,
        max: parseInt(options.max) + 0,
      },
      // padding: [30, 30],
      //////
      // format: {
      //   to: function (value) {
      //     return value;
      //   },
      //   from: function (value) {
      //     return Number(value);
      //   },
      // },
    });

    //////////////////////////
    // Config. interactions //
    //////////////////////////

    const state = this.state;
    const main = state.main;
    const refs = state.refs;

    rangeSliderEl.addEventListener("mouseenter", () => {
      this.setState({
        rangeSlider_active: true,
      });
    });
    rangeSliderEl.addEventListener("mouseleave", () => {
      this.setState({
        rangeSlider_active: false,
      });
    });

    ////////////////////////////////////
    ////////////////////////////////////

    rangeSlider.on("update", () => {
      this.setState({
        price: {
          min: Math.floor(rangeSlider.get()[0]),
          max: Math.floor(rangeSlider.get()[1]),
        },
      });
      //////
      if (this.state.rangeSlider_active) {
        eventbus.emit("price-slider_update", {
          id: "site-header",
          price: {
            min: Math.floor(rangeSlider.get()[0]),
            max: Math.floor(rangeSlider.get()[1]),
          },
        });
      }
    });

    ////////////////////////////////
    // Config. eventbus responses //
    ////////////////////////////////

    eventbus.on("grid_show-all", () => {
      this.setState({
        price: {
          min: parseInt(options.min),
          max: parseInt(options.max),
        },
      });
      rangeSlider.reset();
    });

    ////////////////////////////////////
    ////////////////////////////////////

    eventbus.on("price-slider_update", (args) => {
      if (args.id != "site-header") {
        rangeSlider.set([args.price.min, args.price.max]);
      }
    });
  }

  //////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////

  ////////////////////////////////////
  // Config. state-change responses //
  ////////////////////////////////////

  stateChange(changes) {
    const state = this.state;
    const options = this.options;

    const main = state.main;
    const refs = state.refs;

    const blockName = options.blockName;

    /////////////////////////////////
    /////////////////////////////////

    if ("price" in changes) {
      const rangeSlider_handle_lower = main.element.querySelector(".noUi-handle-lower");
      const rangeSlider_handle_upper = main.element.querySelector(".noUi-handle-upper");
      //////
      rangeSlider_handle_lower.setAttribute("data-value", state.price.min + "€");
      rangeSlider_handle_upper.setAttribute("data-value", state.price.max + "€");
      //////
      eventbus.emit("update_filters_price", { price: state.price });
    }
  }

  //////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////
}

//////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////

export { siteHeader__rangeSlider };
