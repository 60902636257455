import Component from "gia/Component";
import eventbus from "gia/eventbus";
import config from "gia/config";

import { toggle_mod } from "../../utilities/toggle_mod";
import { create_refs } from "../../utilities/create_refs";
import { create_main } from "../../utilities/create_main";

//////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////

class sidepage extends Component {
  constructor(element) {
    super(element);
    //////
    this.ref = {
      button_close: null,
      content_imprint: null,
      content_privacy: null,
      content_about: null,
    };
    //////
    this.options = {
      blockName: "sidepage",
    };
  }

  mount() {
    config.set("log", false);

    const options = this.options;
    const ref = this.ref;

    /////////////////////////////////
    // Config. main- & ref-objects //
    /////////////////////////////////

    const main_methods_custom = [
      {
        method_type: "modifier",
        method_names: ["set_context_imprint", "unset_context_imprint"],
        method_modifier: "context_imprint",
      },
      {
        method_type: "modifier",
        method_names: ["set_context_privacy", "unset_context_privacy"],
        method_modifier: "context_privacy",
      },
      {
        method_type: "modifier",
        method_names: ["set_context_about", "unset_context_about"],
        method_modifier: "context_about",
      },
    ];

    this.setState({
      main: create_main(this.element, options.blockName, main_methods_custom),
      refs: create_refs(ref, options.blockName),
    });

    //////////////////////////
    // Config. interactions //
    //////////////////////////

    const state = this.state;
    const main = state.main;
    const refs = state.refs;

    const button_close = refs.find((ref) => ref.name_element === "button_close");
    button_close.element.addEventListener("click", () => {
      eventbus.emit("sidepage_hide");
    });

    ////////////////////////////////
    // Config. eventbus responses //
    ////////////////////////////////

    eventbus.on("sidepage_show", (args) => {
      const context = args.context;
      //////
      this.setState({
        context: context,
        hidden: false,
      });
    });

    eventbus.on("sidepage_hide", () => {
      this.setState({
        hidden: true,
      });
    });
  }

  //////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////

  ////////////////////////////////////
  // Config. state-change responses //
  ////////////////////////////////////

  stateChange(changes) {
    const state = this.state;
    const options = this.options;

    const main = state.main;
    const refs = state.refs;

    const blockName = options.blockName;

    /////////////////////////////////
    /////////////////////////////////

    if ("hidden" in changes) {
      if (state.hidden) {
        main.hide();
      } else {
        main.show();
      }
    }

    /////////////////////////////////
    /////////////////////////////////

    if ("context" in changes) {
      const content_imprint = refs.find((ref) => ref.name_element === "content_imprint");
      const content_privacy = refs.find((ref) => ref.name_element === "content_privacy");
      const content_about = refs.find((ref) => ref.name_element === "content_about");
      //////
      if (state.context === "imprint") {
        content_privacy.hide();
        content_about.hide();
        content_imprint.show();
        //////
        main.unset_context_privacy();
        main.unset_context_about();
        main.set_context_imprint();
      } else {
        if (state.context === "privacy") {
          content_imprint.hide();
          content_about.hide();
          content_privacy.show();
          //////
          main.unset_context_imprint();
          main.unset_context_about();
          main.set_context_privacy();
        } else {
          if (state.context === "about") {
            content_imprint.hide();
            content_privacy.hide();
            content_about.show();
            //////
            main.unset_context_imprint();
            main.unset_context_privacy();
            main.set_context_about();
          } else {
            main.unset_context_imprint();
            main.unset_context_privacy();
          }
        }
      }
    }
  }

  //////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////
}

//////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////

export { sidepage };
