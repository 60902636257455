import Component from "gia/Component";
import eventbus from "gia/eventbus";
import config from "gia/config";

import { toggle_mod } from "../../utilities/toggle_mod";
import { create_refs } from "../../utilities/create_refs";
import { create_main } from "../../utilities/create_main";

//////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////

class mobileSiteHeader extends Component {
  constructor(element) {
    super(element);
    //////
    this.ref = {
      links: [],
      total: null,
      logo: null,
      //////
      menu: null,
      menuGroups: [],
      menuCloseButton: null,
      //////
      subMenu_gender: null,
    };
    //////
    this.options = {
      blockName: "mobile-site-header",
    };
  }

  mount() {
    config.set("log", false);

    const options = this.options;
    const ref = this.ref;

    /////////////////////////////////
    // Config. main- & ref-objects //
    // + set init. states          //
    /////////////////////////////////

    this.setState({
      main: create_main(this.element, options.blockName),
      refs: create_refs(ref, options.blockName),
      //////
      menu_hidden: true,
      filters_size_active: 0,
      filters_gender_active: 0,
    });

    //////////////////////////
    // Config. interactions //
    //////////////////////////

    const state = this.state;
    const main = state.main;
    const refs = state.refs;

    const links = ref.links;
    for (let link of links) {
      link.addEventListener("click", () => {
        this.setState({
          link_active: link.dataset.title,
          sidepage_hidden: false,
        });
      });
    }

    //////////////////
    //////////////////

    const menuCloseButton = ref.menuCloseButton;
    menuCloseButton.addEventListener("click", () => {
      this.setState({
        menu_hidden: true,
      });
    });

    //////////////////
    //////////////////

    const logo = ref.logo;
    logo.addEventListener("click", () => {
      eventbus.emit("sidepage_show", { context: "about" });
    });

    ////////////////////////////////
    // Config. eventbus responses //
    ////////////////////////////////

    eventbus.on("sidepage_hide", () => {
      this.setState({
        link_active: "null",
        sidepage_hidden: true,
      });
    });

    ///////////////////////////////////
    ///////////////////////////////////

    eventbus.on("mobile-site-header_menu_show", (args) => {
      this.setState({
        menu_hidden: false,
        menu_group_active: args.title,
      });
    });

    eventbus.on("mobile-site-header_menu_hide", (args) => {
      this.setState({
        menu_hidden: true,
      });
    });

    ///////////////////////////////////////////
    // Update total amount (of active items) //
    ///////////////////////////////////////////

    eventbus.on("grid_update_items_active", (args) => {
      this.setState({
        count_items_active: args.count_items_active,
      });
    });

    ///////////////////////////////////////////
    // Update count of active gender filters //
    // (must be > 0 when filtering for size) //
    ///////////////////////////////////////////

    eventbus.on("update_filters_size", (args) => {
      const filters_size_active = this.state.filters_size_active;
      //////
      if (args.mode === "add") {
        this.setState({
          filters_size_active: filters_size_active + 1,
        });
      } else {
        this.setState({
          filters_size_active: filters_size_active - 1,
        });
      }
      //////
      console.log(this.state.filters_size_active);
    });

    eventbus.on("update_filters_gender", (args) => {
      const filters_gender_active = this.state.filters_gender_active;
      //////
      if (args.mode === "add") {
        this.setState({
          filters_gender_active: filters_gender_active + 1,
        });
      } else {
        this.setState({
          filters_gender_active: filters_gender_active - 1,
        });
      }
      //////
      console.log(this.state.filters_gender_active);
    });
  }

  //////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////

  ////////////////////////////////////
  // Config. state-change responses //
  ////////////////////////////////////

  stateChange(changes) {
    const state = this.state;
    const options = this.options;

    const main = state.main;
    const refs = state.refs;
    const ref = this.ref;

    const blockName = options.blockName;

    ///////////////////////////////////////
    ///////////////////////////////////////

    if ("sidepage_hidden" in changes) {
      if (state.sidepage_hidden) {
        eventbus.emit("sidepage_hide");
      } else {
        eventbus.emit("sidepage_show", { context: state.link_active });
      }
    }

    ///////////////////////////////////////
    ///////////////////////////////////////

    if ("count_items_active" in changes) {
      const count_items_active = state.count_items_active;
      const total = refs.find((ref) => ref.name_element === "total");
      //////
      total.element.setAttribute("data-value", count_items_active);
      //////
      if (count_items_active === 1) {
        total.element.parentNode.classList.add(blockName + "__total-wrapper_single");
      } else {
        total.element.parentNode.classList.remove(blockName + "__total-wrapper_single");
      }
    }

    ///////////////////////////////////////
    ///////////////////////////////////////

    if ("filters_size_active" in changes) {
      if (state.filters_size_active > 0) {
        this.setState({
          subMenu_gender_hidden: false,
        });
      } else {
        this.setState({
          subMenu_gender_hidden: true,
        });
      }
    }

    ///////////////////////////////////////
    ///////////////////////////////////////

    if ("menu_hidden" in changes) {
      const menu = refs.find((ref) => ref.name_element === "menu");
      if (state.menu_hidden) {
        menu.hide();
      } else {
        menu.element.style.height = window.innerHeight + "px";
        menu.show();
      }
    }

    ///////////////////////////////////////
    ///////////////////////////////////////

    if ("subMenu_gender_hidden" in changes) {
      const subMenu_gender = ref.subMenu_gender;
      if (state.subMenu_gender_hidden) {
        subMenu_gender.classList.add(blockName + "__sub-menu_hidden_opacity");
        setTimeout(() => {
          subMenu_gender.classList.add(blockName + "__sub-menu_hidden_height");
        }, 333);
      } else {
        subMenu_gender.classList.remove(blockName + "__sub-menu_hidden_height");
        setTimeout(() => {
          // this.position_closeButton();
          subMenu_gender.classList.remove(blockName + "__sub-menu_hidden_opacity");
        }, 333);
      }
    }

    ///////////////////////////////////////
    ///////////////////////////////////////

    if ("menu_group_active" in changes) {
      const menuGroups = this.ref.menuGroups;
      for (let group of menuGroups) {
        if (group.dataset.title === state.menu_group_active) {
          group.classList.remove(blockName + "__menu-group_hidden");
        } else {
          group.classList.add(blockName + "__menu-group_hidden");
        }
      }
    }
  }

  //////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////

  position_closeButton() {
    console.log("test_00");
    //////
    const ref = this.ref;
    const menuCloseButton = ref.menuCloseButton;
    const subMenu_gender = ref.subMenu_gender;
    //////
    const DOMrect_subMenu = subMenu_gender.getBoundingClientRect();
    console.log(DOMrect_subMenu);
    //////
    menuCloseButton.style.bottom = "auto";
    menuCloseButton.style.top = DOMrect_subMenu.bottom + "px";
  }
}

//////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////

export { mobileSiteHeader };
