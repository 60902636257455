import Component from "gia/Component";
import eventbus from "gia/eventbus";
import config from "gia/config";

import { toggle_mod } from "../../utilities/toggle_mod";
import { create_refs } from "../../utilities/create_refs";
import { create_main } from "../../utilities/create_main";

//////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////

class siteHeader__total extends Component {
  constructor(element) {
    super(element);
    //////
    this.ref = {
      total: null,
      label: null,
    };
    //////
    this.options = {
      blockName: "site-header",
    };
  }

  mount() {
    config.set("log", false);

    const options = this.options;
    const ref = this.ref;

    /////////////////////////////////
    // Config. main- & ref-objects //
    /////////////////////////////////

    this.setState({
      main: create_main(this.element, options.blockName),
      refs: create_refs(ref, options.blockName),
    });

    ///////////////////////////////////////////
    // Update total amount (of active items) //
    ///////////////////////////////////////////

    eventbus.on("grid_update_items_active", (args) => {
      this.setState({
        count_items_active: args.count_items_active,
      });
    });
  }

  //////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////

  stateChange(changes) {
    const state = this.state;
    const options = this.options;

    const main = state.main;
    const refs = state.refs;

    const blockName = options.blockName;

    /////////////////////////////////
    /////////////////////////////////

    if ("count_items_active" in changes) {
      const count_items_active = state.count_items_active;
      const total = refs.find((ref) => ref.name_element === "total");
      const label = refs.find((ref) => ref.name_element === "label");
      //////
      total.element.setAttribute("data-value", count_items_active);
      //////
      if (count_items_active === 1) {
        label.element.setAttribute("data-label", "selected pair");
      } else {
        label.element.setAttribute("data-label", "selected pairs");
      }
    }
  }

  //////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////
}

//////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////

export { siteHeader__total };
