import Component from "gia/Component";
import eventbus from "gia/eventbus";
import config from "gia/config";

import { toggle_mod } from "../../../utilities/toggle_mod";
import { create_refs } from "../../../utilities/create_refs";
import { create_main } from "../../../utilities/create_main";

//////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////

class siteHeader__button extends Component {
  constructor(element) {
    super(element);
    //////
    this.ref = {
      filterButtons: [],
      submenu: null,
      submenuButtons: [],
    };
    //////
    this.options = {
      blockName: "site-header__button",
      //////
      id: "defaultValue",
      locking: "defaultValue",
    };
  }

  mount() {
    config.set("log", false);

    const options = this.options;
    const ref = this.ref;

    /////////////////////////////////
    // Config. main- & ref-objects //
    /////////////////////////////////

    this.setState({
      main: create_main(this.element, options.blockName),
      refs: create_refs(ref, options.blockName),
      //////
      filterButtons_active: 0,
      submenuButtons_active: 0,
    });

    //////////////////////////
    // Config. interactions //
    //////////////////////////

    const state = this.state;
    const main = state.main;
    const refs = state.refs;

    main.element.addEventListener("mouseenter", () => {
      this.setState({
        active: true,
      });
    });

    main.element.addEventListener("mouseleave", () => {
      if (!this.state.active_perma) {
        this.setState({
          active: false,
        });
      }
    });

    ///////////////////////////////
    // Lock menu on button-click //
    ///////////////////////////////

    const is_locking = parseInt(options.locking);
    if (is_locking) {
      const filterButtons = ref.filterButtons;
      const submenuButtons = ref.submenuButtons;
      //////
      for (let button of filterButtons) {
        button.addEventListener("click", () => {
          const filterButtons_active = this.state.filterButtons_active;
          const button_is_active = button.classList.contains("filter-button_active");
          //////
          if (button_is_active) {
            this.setState({
              filterButtons_active: filterButtons_active - 1,
            });
          } else {
            this.setState({
              filterButtons_active: filterButtons_active + 1,
            });
          }
          //////
          if (this.state.filterButtons_active > 0) {
            this.setState({
              // active_perma: true,
              submenu_hidden: false,
            });
          } else {
            this.setState({
              // active_perma: false,
              submenu_hidden: true,
            });
          }
        });
      }

      ////////////////////////////////////
      // Unlock on submenu button-click //
      ////////////////////////////////////

      for (let button of submenuButtons) {
        button.addEventListener("click", () => {
          //////
          if (this.state.submenuButtons_active > 0) {
            this.setState({
              active_perma: false,
            });
          } else {
            this.setState({
              active_perma: true,
            });
          }
        });
      }

      ////////////////////////////////////
      ////////////////////////////////////

      eventbus.on("update_filters_gender", (args) => {
        const submenuButtons_active = this.state.submenuButtons_active;
        //////
        if (args.mode === "add") {
          this.setState({
            submenuButtons_active: submenuButtons_active + 1,
          });
        } else {
          this.setState({
            submenuButtons_active: submenuButtons_active - 1,
          });
        }
      });

      //////////////////
      //////////////////

      eventbus.on("grid_filters_gender_active", () => {
        this.setState({
          submenu_hidden: false,
        });
      });
      eventbus.on("grid_filters_gender_inactive", () => {
        if (this.state.filterButtons_active === 0) {
          this.setState({
            submenu_hidden: true,
          });
        }
      });
    }
  }

  //////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////

  ////////////////////////////////////
  // Config. state-change responses //
  ////////////////////////////////////

  stateChange(changes) {
    const state = this.state;
    const options = this.options;

    const main = state.main;
    const ref = this.ref;
    const refs = state.refs;

    const blockName = options.blockName;

    ////////////////////////////////////
    ////////////////////////////////////

    if ("active" in changes) {
      if (state.active) {
        main.activate();
        eventbus.emit("site-header_button_active", { id: options.id });
      } else {
        if (!state.active_perma) {
          main.deactivate();
          eventbus.emit("site-header_button_inactive");
        }
      }
    }

    ////////////////////////////////////
    ////////////////////////////////////

    if ("submenu_hidden" in changes) {
      const submenu = ref.submenu;
      if (state.submenu_hidden) {
        submenu.classList.add("site-header__menu-sub_hidden");
      } else {
        submenu.classList.remove("site-header__menu-sub_hidden");
      }
    }

    ////////////////////////////////////
    ////////////////////////////////////

    if ("filterButtons_active" in changes) {
      this.check_mustBe_active_perma();
    }

    ////////////////////////////////////
    ////////////////////////////////////

    if ("submenuButtons_active" in changes) {
      this.check_mustBe_active_perma();
    }
  }

  //////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////

  check_mustBe_active_perma() {
    const state = this.state;
    const filterButtons_active = state.filterButtons_active;
    const submenuButtons_active = state.submenuButtons_active;
    //////
    if (filterButtons_active > 0 && submenuButtons_active === 0) {
      this.setState({
        active_perma: true,
      });
    } else {
      if (filterButtons_active > 0 && submenuButtons_active > 0) {
        this.setState({
          active_perma: false,
        });
      } else {
        if (filterButtons_active === 0) {
          this.setState({
            active_perma: false,
          });
        }
      }
    }
  }
}

//////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////

export { siteHeader__button };
