function toggle_mod(ref, modifierName, mode) {
  if (ref != null && ref != undefined) {
    const element = ref.element;
    const name_element = ref.name_element;
    const name_block = ref.name_block;
    //////
    if (name_block != null && name_block != undefined) {
      let className = name_block;
      //////
      if (name_element.length > 0) {
        className = className + "__" + name_element + "_" + modifierName;
      } else {
        className = className + "_" + modifierName;
      }
      //////
      if (element != null && element != undefined) {
        if (mode === "add") {
          element.classList.add(className);
        } else {
          element.classList.remove(className);
        }
      } else {
        console.error("reference-element is null or undefined");
      }
    } else {
      console.error("name_block is null or undefined");
    }
  } else {
    console.error("reference null or undefined");
  }
}

export { toggle_mod };
