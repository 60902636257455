import { toggle_mod } from "toggle_mod";

////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////

function Main(element, blockName, customMethods) {
  this.element = element;
  this.name_block = blockName;
  this.name_element = "";
  //////
  this.hide = () => {
    toggle_mod(this, "hidden", "add");
  };
  this.show = () => {
    toggle_mod(this, "hidden", "remove");
  };
  //////
  this.activate = () => {
    toggle_mod(this, "active", "add");
  };
  this.deactivate = () => {
    toggle_mod(this, "active", "remove");
  };
  //////
  if (customMethods != undefined && customMethods.length > 0) {
    for (let method of customMethods) {
      const method_type = method.method_type;
      //////
      if (method_type === "modifier") {
        const method_names = method.method_names;
        const method_modifier = method.method_modifier;
        //////
        this[method_names[0]] = () => {
          toggle_mod(this, method_modifier, "add");
        };
        this[method_names[1]] = () => {
          toggle_mod(this, method_modifier, "remove");
        };
      }
    }
  }
}

////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////

function create_main(compEl, blockName, customMethods) {
  const main = new Main(compEl, blockName, customMethods);
  return main;
}

////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////

export { create_main };
