import Component from "gia/Component";
import eventbus from "gia/eventbus";
import config from "gia/config";

import insertionQ from "insertion-query";

import { toggle_mod } from "../../utilities/toggle_mod";

//////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////

class cart extends Component {
  constructor(element) {
    super(element);
    //////
    this.ref = {
      quantity_total: null,
      price_total: null,
      wrapper_items: null,
      //////
      button_checkout: null,
      button_close: null,
    };
    //////
    this.options = {
      blockName: "cart",
      thumbnails: "defaultValue",
    };
  }

  mount() {
    config.set("log", false);

    ////////////////////////
    // Get thumbnail-URLs //
    ////////////////////////

    ///////////////////////////////////////////////////
    // Comp config. + build main & reference objects //
    ///////////////////////////////////////////////////

    this.setState({
      initialised: false,
      //////
      total_price: 0,
      totel_quantity: 0,
      //////
      items: [],
      thumbnails: [],
      //////
      main: {
        element: this.element,
        name_block: this.options.blockName,
        name_element: "",
      },
      refs: {
        button_checkout: {
          element: this.ref.button_checkout,
          name_block: this.options.blockName,
          name_element: "button_checkout",
        },
        button_close: {
          element: this.ref.button_close,
          name_block: this.options.blockName,
          name_element: "button_close",
        },
      },
    });

    const refs = this.state.refs;

    ///////////////////////////////////////////
    // Track insertion of Shopify cart items //
    ///////////////////////////////////////////

    insertionQ(".shopify-buy__cart-item").every((element) => {
      const element_id = element.id;
      const element_title = element.querySelector(".shopify-buy__cart-item__title").textContent;
      const element_handle = element_title.replace(/\s+/g, "-").toLowerCase();
      const element_size = element.querySelector(".shopify-buy__cart-item__variant-title").textContent;
      const element_price = Number(element.querySelector(".shopify-buy__cart-item__price").textContent.replace(/[^0-9\.-]+/g, ""));
      //////
      const item_new = {
        id: element_id,
        title: element_title,
        handle: element_handle,
        size: element_size,
        price: element_price,
        rendered: false,
        thumbnail: this.state.thumbnails.find((thumbnail) => {
          return thumbnail.handle === element_handle;
        }),
        //////
        delete: () => {
          const item_el = document.querySelector("[data-id='" + element_id + "']");
          const item_button_decrement = document.querySelector(".shopify-buy__quantity-decrement[data-line-item-id='" + element_id + "']");
          //////
          item_button_decrement.click();
          item_el.remove();
          eventbus.emit("cart_itemRemoved", { id: element_id, title: element_title });
          //////
          const items_curr = this.state.items;
          const items_new = [...items_curr];
          const item_index = items_new.indexOf(
            items_new.find((item) => {
              return item.id === element_id;
            })
          );
          //////
          items_new.splice(item_index, 1);
          //////
          if (items_new.length === 0) {
            this.setState({
              total_quantity: 0,
              total_price: 0,
            });
          }
          //////
          this.setState({
            items: items_new,
          });
        },
      };
      //////
      const items_curr = this.state.items;
      let item_is_new = true;
      //////
      if (items_curr.length > 0) {
        for (let item_curr of items_curr) {
          if (item_curr.id === item_new.id) {
            item_is_new = false;
          }
        }
      }
      //////
      if (item_is_new) {
        this.setState({
          items: [...items_curr, item_new],
        });
      } else {
        console.warn("item has already been added to comp.-state");
      }
    });

    /////////////////////////////
    // Config. checkout-button //
    /////////////////////////////

    const button_checkout = this.ref.button_checkout;
    button_checkout.addEventListener("click", () => {
      document.querySelector(".shopify-buy__btn--cart-checkout").click();
    });

    //////////////////////////
    // Config. close-button //
    //////////////////////////

    const button_close = refs.button_close;
    button_close.element.addEventListener("click", () => {
      this.setState({
        hidden: true,
      });
    });

    //////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////

    this.setState({
      initialised: true,
    });

    ////////////////////////////////
    // Config. eventbus responses //
    ////////////////////////////////

    eventbus.on("cart_open", () => {
      this.setState({
        hidden: false,
      });
    });

    eventbus.on("cart_close", () => {
      this.setState({
        hidden: true,
      });
    });

    ////////////////////////////////////
    ////////////////////////////////////

    eventbus.on("filter-menu_VAR_floating_show", () => {
      this.setState({
        hidden: true,
      });
    });

    ////////////////////////////////////
    ////////////////////////////////////

    eventbus.on("grid_itemInitialised", (args) => {
      const thumbnails_curr = this.state.thumbnails;
      const thumbnails_new = [...thumbnails_curr, args.thumbnail];
      this.setState({
        thumbnails: thumbnails_new,
      });
    });

    //////////////////////////////////////
    // Update height on window resizing //
    //////////////////////////////////////

    // eventbus.on("window_resize", (args) => {
    //   this.setState({
    //     height: args.window.height,
    //   });
    // });
  }

  //////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////

  stateChange(changes) {
    const state = this.state;
    const options = this.options;

    const main = state.main;
    const refs = state.refs;

    const blockName = options.blockName;

    /////////////////////////////////
    /////////////////////////////////

    if ("hidden" in changes) {
      if (state.hidden) {
        toggle_mod(main, "hidden", "add");
        eventbus.emit("cart_close");
      } else {
        this.setState({
          height: window.innerHeight,
        });
        toggle_mod(main, "hidden", "remove");
      }
    }

    /////////////////////////////////
    /////////////////////////////////

    if ("items" in changes) {
      const items = state.items;
      //////
      for (let item of items) {
        if (!item.rendered) {
          this.render_item(item);
          item.rendered = true;
        } else {
          console.warn("item has already been rendered inside cart");
        }
        //////
        this.setState({
          total_quantity: items.length,
          total_price: this.get_total_price(items),
        });
      }
    }

    ////////////////////////////////////
    ////////////////////////////////////

    if ("total_price" in changes) {
      if (state.initialised) {
        const price_total = this.ref.price_total;
        price_total.innerHTML = state.total_price;
      }
    }

    ////////////////////////////////////
    ////////////////////////////////////

    if ("total_quantity" in changes) {
      if (state.initialised) {
        const total_quantity = state.total_quantity;
        const quantity_total = this.ref.quantity_total;
        //////
        quantity_total.innerHTML = total_quantity;
        //////
        if (total_quantity === 0) {
          this.setState({
            checkout_disabled: true,
          });
        } else {
          this.setState({
            checkout_disabled: false,
          });
        }
        //////
        if (total_quantity === 1) {
          quantity_total.classList.add("cart__amount_quantity_single");
        } else {
          quantity_total.classList.remove("cart__amount_quantity_single");
        }
        //////
        eventbus.emit("cart_update_quantity", { quantity: total_quantity });
      }
    }

    ////////////////////////////////////
    ////////////////////////////////////

    if ("checkout_disabled" in changes) {
      const button_checkout = refs.button_checkout;
      //////
      if (state.checkout_disabled) {
        toggle_mod(button_checkout, "disabled", "add");
      } else {
        toggle_mod(button_checkout, "disabled", "remove");
      }
    }

    /////////////////////////////////
    /////////////////////////////////

    if ("height" in changes) {
      main.element.style.height = state.height + "px";
    }
  }

  //////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////

  render_item(item) {
    const wrapper_item = this.ref.wrapper_items;
    const blockName = this.options.blockName;
    //////
    const markup = `
      <div class="${blockName}__item" 
           data-id="${item.id}">
        <div class="${blockName}__image">
          <img src=" ${item.thumbnail != undefined ? item.thumbnail.src : "null"}">
        </div>
        <div class="${blockName}__item-info">
          <p>${item.title}</p>
          ${item.size.length > 0 ? "<p>Size " + item.size + "</p>" : ""}
        </div>
        <div class="${blockName}__item-price">
          <span class="${blockName}__amount ${blockName}__amount_price">${item.price}</span>
        </div>
        <button class="${blockName}__button ${blockName}__button_delete"
                data-delete-button-id="${item.id}">
          <img src="/assets/img/bubble_delete.svg" alt="check">
        </button>
      </div>`;
    //////
    wrapper_item.insertAdjacentHTML("beforeend", markup);
    //////
    const button_delete = document.querySelector(`[data-delete-button-id="${item.id}"]`);
    button_delete.addEventListener("click", () => {
      item.delete();
    });
    //////
    eventbus.emit("cart_itemAdded", { id: item.id, title: item.title });
  }

  ////////////////////////////////////
  ////////////////////////////////////

  get_total_price(items) {
    let price_total = 0;
    for (let item of items) {
      price_total += item.price;
    }
    return price_total;
  }
}

//////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////

export { cart };
