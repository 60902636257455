import Component from "gia/Component";
import eventbus from "gia/eventbus";
import config from "gia/config";

import { toggle_mod } from "../../utilities/toggle_mod";
import { create_refs } from "../../utilities/create_refs";
import { create_main } from "../../utilities/create_main";

//////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////

class mobileFilterMenu__button_toggle extends Component {
  constructor(element) {
    super(element);
    //////
    this.ref = {
      bubble_count: null,
    };
    //////
    this.options = {
      blockName: "mobile-filter-menu__button_toggle",
    };
  }

  mount() {
    config.set("log", false);

    const options = this.options;
    const ref = this.ref;

    /////////////////////////////////
    // Config. main- & ref-objects //
    /////////////////////////////////

    // const main_methods_custom = [
    //   {
    //     method_type: "modifier",
    //     method_names: ["stick", "unstick"],
    //     method_modifier: "sticky",
    //   },
    // ];

    this.setState({
      main: create_main(this.element, options.blockName),
      refs: create_refs(ref, options.blockName),
    });

    //////////////////////////
    // Config. interactions //
    //////////////////////////

    const state = this.state;
    const main = state.main;
    const refs = state.refs;

    main.element.addEventListener("click", () => {
      eventbus.emit("mobile-filter-menu_show");
    });

    ////////////////////////////////
    // Config. eventbus responses //
    ////////////////////////////////

    eventbus.on("scroll_enter_page", () => {
      this.setState({
        active: true,
      });
    });
    eventbus.on("scroll_leave_page", () => {
      this.setState({
        active: false,
      });
    });

    ////////////////////////////////////
    ////////////////////////////////////

    eventbus.on("update_count_filters_active", (args) => {
      this.setState({
        count_filters_active: args.count_filters_active,
      });
    });
  }

  //////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////

  ////////////////////////////////////
  // Config. state-change responses //
  ////////////////////////////////////

  stateChange(changes) {
    const state = this.state;
    const options = this.options;

    const main = state.main;
    const refs = state.refs;

    const blockName = options.blockName;

    ////////////////////////////////////
    ////////////////////////////////////

    if ("active" in changes) {
      if (state.active) {
        main.activate();
      } else {
        main.deactivate();
      }
    }

    ////////////////////////////////////
    ////////////////////////////////////

    if ("count_filters_active" in changes) {
      const count_filters_active = state.count_filters_active;
      const bubble_count = refs.find((ref) => ref.name_element === "bubble_count");
      //////
      const bubble_count_el = bubble_count.element;
      //////
      if (count_filters_active > 0) {
        bubble_count_el.classList.add("button__bubble_count_active");
      } else {
        bubble_count_el.classList.remove("button__bubble_count_active");
      }
      //////
      bubble_count_el.setAttribute("data-count", count_filters_active);
    }
  }

  //////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////
}

//////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////

export { mobileFilterMenu__button_toggle };
