function toggle_state_element_PROTO(el, blockName, elementName, stateName, mode) {
  let className = blockName;
  //////
  if (elementName.length > 0) {
    className = className + "__" + elementName + "_" + stateName;
  } else {
    className = className + "_" + stateName;
  }
  //////
  if (el != null && el != undefined) {
    if (mode === "add") {
      el.classList.add(className);
    } else {
      el.classList.remove(className);
    }
  }
}

export { toggle_state_element_PROTO };
