import Component from "gia/Component";
import eventbus from "gia/eventbus";
import config from "gia/config";

import { toggle_mod } from "../../utilities/toggle_mod";
import { create_refs } from "../../utilities/create_refs";
import { create_main } from "../../utilities/create_main";

//////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////

class activeFiltersDisplay extends Component {
  constructor(element) {
    super(element);
    //////
    this.ref = {};
    //////
    this.options = {
      blockName: "active-filters-display",
    };
  }

  mount() {
    config.set("log", false);

    const options = this.options;
    const ref = this.ref;

    /////////////////////////////////
    // Config. main- & ref-objects //
    /////////////////////////////////

    // const main_methods_custom = [
    //   {
    //     method_type: "modifier",
    //     method_names: ["stick", "unstick"],
    //     method_modifier: "sticky",
    //   },
    // ];

    this.setState({
      main: create_main(this.element, options.blockName),
      refs: create_refs(ref, options.blockName),
    });

    //////////////////////////
    // Config. interactions //
    //////////////////////////

    const state = this.state;
    const main = state.main;
    const refs = state.refs;

    ////////////////////////////////
    // Config. eventbus responses //
    ////////////////////////////////

    eventbus.on("scroll_enter_page", () => {
      this.setState({
        position: "top",
      });
    });

    eventbus.on("scroll_leave_page", () => {
      this.setState({
        position: "bottom",
      });
    });

    ////////////////////////////////////
    ////////////////////////////////////

    eventbus.on("filter-menu_show", () => {
      this.setState({
        hidden: true,
      });
    });

    eventbus.on("filter-menu_hide", () => {
      this.setState({
        hidden: false,
      });
    });

    ////////////////////////////////////
    ////////////////////////////////////

    eventbus.on("grid_is_filtered", () => {
      this.setState({
        active: true,
      });
    });

    eventbus.on("grid_is_unfiltered", () => {
      this.setState({
        active: false,
      });
    });
  }

  //////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////

  ////////////////////////////////////
  // Config. state-change responses //
  ////////////////////////////////////

  stateChange(changes) {
    const state = this.state;
    const options = this.options;

    const main = state.main;
    const refs = state.refs;

    const blockName = options.blockName;

    ////////////////////////////////////
    ////////////////////////////////////

    if ("hidden" in changes) {
      if (state.hidden) {
        main.hide();
      } else {
        main.show();
      }
    }

    ////////////////////////////////////
    ////////////////////////////////////

    if ("active" in changes) {
      if (state.active) {
        main.activate();
      } else {
        main.deactivate();
      }
    }

    ////////////////////////////////////
    ////////////////////////////////////

    if ("position" in changes) {
      if (state.position === "top") {
        main.element.classList.add(blockName + "_position_top");
      } else {
        main.element.classList.remove(blockName + "_position_top");
      }
    }
  }

  //////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////
}

//////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////

export { activeFiltersDisplay };
