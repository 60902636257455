import Component from "gia/Component";
import eventbus from "gia/eventbus";
import config from "gia/config";

import { toggle_mod } from "../../utilities/toggle_mod";
import { create_refs } from "../../utilities/create_refs";
import { create_main } from "../../utilities/create_main";

//////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////

class mobileFilterMenu extends Component {
  constructor(element) {
    super(element);
    //////
    this.ref = {
      button_close: null,
      total: null,
    };
    //////
    this.options = {
      blockName: "mobile-filter-menu",
    };
  }

  mount() {
    config.set("log", false);

    const options = this.options;
    const ref = this.ref;

    /////////////////////////////////
    // Config. main- & ref-objects //
    /////////////////////////////////

    this.setState({
      main: create_main(this.element, options.blockName),
      refs: create_refs(ref, options.blockName),
    });

    //////////////////////////
    // Config. interactions //
    //////////////////////////

    const state = this.state;
    const main = state.main;
    const refs = state.refs;

    const button_close = ref.button_close;
    button_close.addEventListener("click", () => {
      this.setState({
        hidden: true,
      });
      eventbus.emit("mobile-filter-menu_hide");
    });

    ////////////////////////////////
    // Config. eventbus responses //
    ////////////////////////////////

    eventbus.on("mobile-filter-menu_show", () => {
      this.setState({
        hidden: false,
      });
    });

    eventbus.on("grid_update_items_active", (args) => {
      this.setState({
        count_items_active: args.count_items_active,
      });
    });
  }

  //////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////

  ////////////////////////////////////
  // Config. state-change responses //
  ////////////////////////////////////

  stateChange(changes) {
    const state = this.state;
    const options = this.options;

    const main = state.main;
    const refs = state.refs;

    const blockName = options.blockName;

    /////////////////////////////////
    /////////////////////////////////

    if ("hidden" in changes) {
      if (state.hidden) {
        main.hide();
      } else {
        main.show();
      }
    }

    ////////////////////////////////////
    ////////////////////////////////////

    if ("count_items_active" in changes) {
      const count_items_active = state.count_items_active;
      const total = refs.find((ref) => ref.name_element === "total");
      //////
      total.element.setAttribute("data-value", count_items_active);
      //////
      if (count_items_active === 1) {
        total.element.classList.add(blockName + "__total-value_single");
      } else {
        total.element.classList.remove(blockName + "__total-value_single");
      }
    }
  }

  //////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////
}

//////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////

export { mobileFilterMenu };
