import Component from "gia/Component";
import eventbus from "gia/eventbus";
import config from "gia/config";

import { toggle_mod } from "../../utilities/toggle_mod";
import { create_refs } from "../../utilities/create_refs";
import { create_main } from "../../utilities/create_main";
import { height } from "dom7";

//////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////

class pageCover_orientationInfo_phone extends Component {
  constructor(element) {
    super(element);
    //////
    this.ref = {};
    //////
    this.options = {
      blockName: "page-cover_orientation-info_phone",
    };
  }

  mount() {
    config.set("log", false);

    const options = this.options;
    const ref = this.ref;

    /////////////////////////////////
    // Config. main- & ref-objects //
    /////////////////////////////////

    this.setState({
      main: create_main(this.element, options.blockName),
      refs: create_refs(ref, options.blockName),
      //////
      size: {
        width: window.innerWidth,
        height: window.innerHeight,
      },
    });

    //////////////////////////
    // Config. interactions //
    //////////////////////////

    const state = this.state;
    const main = state.main;
    const refs = state.refs;

    ////////////////////////////////
    // Config. eventbus responses //
    ////////////////////////////////

    eventbus.on("window_resize", (args) => {
      this.setState({
        size: {
          width: args.window.width,
          height: args.window.height,
        },
      });
    });
  }

  //////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////

  ////////////////////////////////////
  // Config. state-change responses //
  ////////////////////////////////////

  stateChange(changes) {
    const state = this.state;
    const options = this.options;

    const main = state.main;
    const refs = state.refs;

    const blockName = options.blockName;

    /////////////////////////////////
    /////////////////////////////////

    if ("size" in changes) {
      const width = state.size.width;
      const height = state.size.height;
      //////
      main.element.style.width = width + "px";
      main.element.style.height = height + "px";
    }
  }

  //////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////
}

//////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////

export { pageCover_orientationInfo_phone };
