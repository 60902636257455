import Component from "gia/Component";
import eventbus from "gia/eventbus";
import config from "gia/config";

import { toggle_mod } from "../../utilities/toggle_mod";
import { create_refs } from "../../utilities/create_refs";
import { create_main } from "../../utilities/create_main";

////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////

class sidebar extends Component {
  constructor(element) {
    super(element);
    //////
    this.ref = {
      button_cart: null,
      button_plus: null,
      button_minus: null,
    };
    //////
    this.options = {
      blockName: "sidebar",
    };
  }

  mount() {
    config.set("log", false);

    const options = this.options;
    const ref = this.ref;

    /////////////////////////////////
    // Config. main- & ref-objects //
    /////////////////////////////////

    this.setState({
      main: create_main(this.element, options.blockName),
      refs: create_refs(ref, options.blockName),
    });

    //////////////////////////////
    // Set initial quantity (0) //
    //////////////////////////////

    this.setState({
      quantity: 0,
    });

    //////////////////////////
    // Config. interactions //
    //////////////////////////

    ref.button_cart.addEventListener("click", () => {
      eventbus.emit("cart_open");
    });

    ////////////////////////////////////
    ////////////////////////////////////

    ref.button_plus.addEventListener("click", () => {
      eventbus.emit("update_grid_columns", { increase: true });
    });
    ref.button_minus.addEventListener("click", () => {
      eventbus.emit("update_grid_columns", { increase: false });
    });

    ////////////////////////////////
    // Config. eventbus responses //
    ////////////////////////////////

    eventbus.on("cart_update_quantity", (args) => {
      this.setState({
        quantity: args.quantity,
      });
    });

    ////////////////////////////////////
    ////////////////////////////////////

    eventbus.on("gridItem_click", () => {
      this.setState({
        minimized: true,
      });
    });

    ////////////////////////////////////
    ////////////////////////////////////

    eventbus.on("product-viewer_hidden", () => {
      this.setState({
        minimized: false,
      });
    });

    //////////////////////////////////////
    // Update height on window resizing //
    //////////////////////////////////////

    eventbus.on("window_resize", (args) => {
      this.setState({
        height: args.window.height,
      });
    });
  }

  ////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  stateChange(changes) {
    const state = this.state;
    const options = this.options;

    const main = state.main;
    const refs = state.refs;

    const blockName = options.blockName;

    /////////////////////////////////
    /////////////////////////////////

    if ("quantity" in changes) {
      const quantity = state.quantity;
      const button_cart = refs.find((ref) => ref.name_element === "button_cart");
      //////
      button_cart.element.setAttribute("data-quantity", state.quantity);
      if (quantity > 0) {
        button_cart.show();
      } else {
        button_cart.hide();
      }
    }

    /////////////////////////////////
    /////////////////////////////////

    if ("minimized" in changes) {
      const button_plus = refs.find((ref) => ref.name_element === "button_plus");
      const button_minus = refs.find((ref) => ref.name_element === "button_minus");
      //////
      if (state.minimized) {
        button_plus.hide();
        button_minus.hide();
      } else {
        button_plus.show();
        button_minus.show();
      }
    }

    /////////////////////////////////
    /////////////////////////////////

    if ("height" in changes) {
      main.element.style.height = state.height + "px";
    }
  }
}

////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////

export { sidebar };
